var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c(
        "label",
        {
          staticClass: "d-block",
          attrs: { for: `rating-input--${_vm.inputId}` },
        },
        [
          _vm._v(" Filter by rating "),
          _c("span", { staticClass: "text-muted rb-text-help" }, [
            _vm._v(" (Optional) "),
          ]),
          _c(
            "el-tooltip",
            {
              attrs: {
                "open-delay": 500,
                content:
                  "Only receive notifications when a review has a specific rating",
                placement: "top",
              },
            },
            [
              _c("help-circle-icon", {
                staticClass: "cursor-pointer text-muted rb-icon-sm",
                attrs: { title: "" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-select",
        {
          attrs: {
            id: `rating-input--${_vm.inputId}`,
            name: "star-filter",
            placeholder: "Select Star Filter",
            size: "small",
          },
          on: { change: _vm.selectFilter },
          model: {
            value: _vm.starFilter,
            callback: function ($$v) {
              _vm.starFilter = $$v
            },
            expression: "starFilter",
          },
        },
        _vm._l(_vm.starOptions, function (option) {
          return _c("el-option", {
            key: option.id,
            attrs: { value: option.id, label: option.text },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }