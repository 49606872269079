var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c(
        "label",
        [
          _vm._v(
            " " +
              _vm._s(_vm.isResponseEmail ? "Response emails" : "Emails") +
              " "
          ),
          _vm.tooltip
            ? _c(
                "el-tooltip",
                {
                  attrs: {
                    "open-delay": 500,
                    content: _vm.tooltip,
                    placement: "top",
                  },
                },
                [
                  _c("help-circle-icon", {
                    staticClass: "cursor-pointer text-muted rb-icon-sm",
                    attrs: { title: "" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._l(_vm.lines, function (line, index) {
        return _c(
          "div",
          { key: index, staticClass: "row", on: { change: _vm.emitChange } },
          [
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c("base-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: _vm.modelValidations.email,
                      expression: "modelValidations.email",
                    },
                  ],
                  class: {
                    "mb-2": _vm.lines.length > 1,
                    "mb-0": _vm.lines.length <= 1,
                  },
                  attrs: {
                    error: _vm.getError(`${_vm.formName}.email-${line.id}`),
                    name: `email-${line.id}`,
                    type: "email",
                    placeholder: "Enter email",
                  },
                  model: {
                    value: line.email,
                    callback: function ($$v) {
                      _vm.$set(line, "email", $$v)
                    },
                    expression: "line.email",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-2 px-0 mx-auto" },
              [
                _c("el-button", {
                  attrs: {
                    circle: "",
                    type: "danger",
                    size: "mini",
                    icon: "el-icon-minus",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.removeLine(index)
                    },
                  },
                }),
                index === _vm.lines.length - 1
                  ? _c("el-button", {
                      attrs: {
                        type: "success",
                        size: "mini",
                        icon: "el-icon-plus",
                        circle: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addLine()
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }