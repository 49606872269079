import { NotificationsHttp } from '@/services/api';

class NotificationsHelper {
  constructor() {
    /**
     * Constructs payload dict to GET or POST to /notifications
     *
     */

    // Construct basic notifications object
    this.notifications = {
      email: [],
      slack: '',
      frequency: 'daily',
      options: {},
    };

    // Settings for NotificationsHttp routes
    this.NotificationsHttp = NotificationsHttp;
    this.data = null;
  }

  async getNotificationEndpoint() {
    /**
     * gets Notification endpoint
     * @params Http get request
     * @return Promise with response
     */

    return this.NotificationsHttp.getNotifications();
  }

  async postNotificationEndpoint(payload) {
    return this.NotificationsHttp.postNotifications(payload);
  }

  async testNotificationEndpoint(payload) {
    return this.NotificationsHttp.patchNotifications(payload);
  }

  listToArray = (list) => {
    // Transforms a comma delmited list into an array
    // Used for emails
    const listArray = list.split(',');
    const emailArray = listArray.map((email) => email.trim());

    return emailArray;
  }

  arrayToList = (array) => {
    // Transforms an array into a comma delimited list
    const list = array.map((email) => email.trim()).join(',');

    return list;
  }

  // Converts string to boolean
  // Used primarily for copybox
  strToBool = (string) => string === 'true';

  // Converts boolean to string
  boolToStr = (bool) => (bool ? 'true' : 'false');
}

export default NotificationsHelper;
