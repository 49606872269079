import NotificationsHelper from './NotificationsHelper';

class AmsNotificationsHelper extends NotificationsHelper {
  async getNotifications() {
    // Resolves or rejects Promise
    // populates constructor
    return this.getNotificationEndpoint()
      .then((response) => {
        const { data } = response;
        const { ams } = data;

        // Populate constructor only if there is data
        // otherwise use defaults
        /**
         * @todo Ams object contains array of user and emails, but outputs as just ams: {emails: }
         */
        if (ams) {
          this.populateConstructor(ams);
        }
      });
  }

  async postNotifications(payload) {
    // converts and posts the payload
    const outputDict = this.populatePost(payload);

    return this.postNotificationEndpoint(outputDict);
  }

  populateConstructor(data) {
    /**
     * @params {Object} populates response data into Object
     * format to populate class
     */

    /**
     * @todo Temporary key mapping to consume current API endpoint
     * @todo Endpoint to be refactored
     *
     */

    // Assumes only email is in ams entry
    const translateDefs = {
      // key: 'api key'
      email: 'emails',
    };

    // Populate non-option fields
    Object.keys(translateDefs).forEach((key) => {
      const dataKey = translateDefs[key];
      // find each value in translateDefs in data
      if (Object.prototype.hasOwnProperty.call(data, dataKey)) {
        // emails are in array format already
        this.notifications[key] = data[dataKey];
      }
    });
  }

  populatePost(payload) {
    /**
     * @todo Endpoint to be refactored
     * @todo emails is the only entry
     * @todo Clean section
     *
     */

    const translateDefs = {
      // key: 'api key'
      email: 'emails',
    };

    const outputPayload = {};
    const key = 'email';
    const dataKey = translateDefs[key];

    // Assumes email is only entry for ams
    outputPayload[dataKey] = this.arrayToList(payload[key]);

    // Stringify the output payload
    const jsonString = JSON.stringify(outputPayload);

    return { ams: jsonString };
  }
}

export default AmsNotificationsHelper;
