import NotificationsHelper from './NotificationsHelper';

class CopyboxNotificationsHelper extends NotificationsHelper {
  constructor() {
    /**
     * Constructs Copybox notifications object
     *
     */
    super();

    // Sets the notifications options
    this.notifications.options = {
      type: 'detailed',
      canonical: false,
      name: false,
      brand: false,
      description: false,
      description_type: false,
      features: false,
      images: false,
      variations: false,
      categories: false,
      details: false,
      badges: false,
    };
  }

  async getNotifications() {
    // Resolves or rejects Promise
    // populates constructor
    return this.getNotificationEndpoint()
      .then((response) => {
        const { data } = response;

        // Populate constructor only if there is data
        // otherwise use defaults
        if (data) {
          this.populateConstructor(data);
        }
      });
  }

  async postNotifications(payload) {
    // converts and posts the payload
    const outputDict = this.populatePost(payload);

    return this.postNotificationEndpoint(outputDict);
  }

  populateConstructor(data) {
    /**
     * @params {Object} populates response data into Object
     * format to populate class
     */

    /**
     * @todo Temporary key mapping to consume current API endpoint
     * @todo Endpoint to be refactored
     *
     */

    const translateDefs = {
      // key: 'api key'
      email: 'cbemails',
      slack: 'cbslack',
      frequency: 'cbfrequency',
    };

    const translateOptions = {
      type: 'cbtype',
      canonical: 'cbcanonical',
      name: 'cbname',
      brand: 'cbbrand',
      description: 'cbdescription',
      description_type: 'cbdtype',
      features: 'cbfeatures',
      images: 'cbimages',
      variations: 'cbvariations',
      categories: 'cbcategories',
      details: 'cbdetails',
      badges: 'cbbadges',
    };

    /**
     * @todo convert to bool before sending to vue
     */
    // List of keys that will require conversion from string to bool
    const cbBooleanList = [
      'cbcanonical',
      'cbname',
	    'cbbrand',
	    'cbdescription',
	    'cbfeatures',
	    'cbimages',
	    'cbvariations',
	    'cbcategories',
	    'cbdetails',
	    'cbbadges',
	    'cbdtype',
    ];

    // Populate non-option fields
    Object.keys(translateDefs).forEach((key) => {
      const dataKey = translateDefs[key];
      // find each value in translateDefs in data
      if (Object.prototype.hasOwnProperty.call(data, dataKey)) {
        if (key === 'email') {
          this.notifications[key] = this.listToArray(data[dataKey]);
        } else {
          this.notifications[key] = data[dataKey];
        }
      }
    });

    // Populate option fields
    Object.keys(translateOptions).forEach((key) => {
      const dataKey = translateOptions[key];
      // find each value in translateOptions in data
      if (Object.prototype.hasOwnProperty.call(data, dataKey)) {
        /**
         * @todo items listed in cbBooleanList should be transmitted as bool to be consistent
         */
        if (cbBooleanList.includes(dataKey)) {
          // convert to boolean from string
          this.notifications.options[key] = this.strToBool(data[dataKey]);
        } else {
          this.notifications.options[key] = data[dataKey];
        }
      }
    });
  }

  populatePost(payload) {
    /**
     * @todo Temporary key mapping to consume current API endpoint
     * @todo Endpoint to be refactored
     *
     */

    const translateDefs = {
      // key: 'api key'
      email: 'cbemail',
      slack: 'cbslack',
      frequency: 'cbfrequency',
    };

    const translateOptions = {
      type: 'cbtype',
      canonical: 'cbcanonical',
      name: 'cbname',
      brand: 'cbbrand',
      description: 'cbdescription',
      description_type: 'cbdtype',
      features: 'cbfeatures',
      images: 'cbimages',
      variations: 'cbvariations',
      categories: 'cbcategories',
      details: 'cbdetails',
      badges: 'cbbadges',
    };

    // List of keys that will require conversion from bool to string
    /**
     * @todo post request should take booleans
     */
    const cbBooleanList = [
      'cbcanonical',
      'cbname',
	    'cbbrand',
	    'cbdescription',
	    'cbfeatures',
	    'cbimages',
	    'cbvariations',
	    'cbcategories',
	    'cbdetails',
	    'cbbadges',
	    'cbdtype',
    ];

    const outputPayload = {};

    // Populate non-option fields
    Object.keys(translateDefs).forEach((key) => {
      const dataKey = translateDefs[key];
      // find each value in translateDefs in data
      if (key === 'email') {
        outputPayload[dataKey] = this.arrayToList(payload[key]);
      } else {
        outputPayload[dataKey] = payload[key];
      }
    });

    // Populate option fields
    const { options } = payload;
    Object.keys(translateOptions).forEach((key) => {
      const dataKey = translateOptions[key];
      if (cbBooleanList.includes(dataKey)) {
        // convert to string from boolean
        outputPayload[dataKey] = this.boolToStr(options[key]);
      } else {
        outputPayload[dataKey] = options[key];
      }
    });

    // Stringify the output payload
    const jsonString = JSON.stringify(outputPayload);

    return { cb: jsonString };
  }
}

export default CopyboxNotificationsHelper;
