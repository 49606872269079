var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "mb-4" },
      [
        _c(
          "label",
          { staticClass: "d-block", attrs: { for: "buyBoxMerchant" } },
          [_vm._v(" Buy Box merchant ")]
        ),
        _c(
          "el-select",
          {
            attrs: {
              id: "buyBoxMerchant",
              placeholder: "Select merchant",
              size: "small",
              clearable: "",
              filterable: "",
            },
            on: {
              clear: function ($event) {
                _vm.merchant = ""
              },
              change: _vm.updateOptions,
            },
            model: {
              value: _vm.merchant,
              callback: function ($$v) {
                _vm.merchant = $$v
              },
              expression: "merchant",
            },
          },
          [
            _c("el-option", { attrs: { value: "", label: "All Merchants" } }),
            _vm._l(_vm.sellerList, function (option) {
              return _c("el-option", {
                key: option.seller,
                attrs: { value: option.seller, label: option.name },
              })
            }),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mb-4" },
      [
        _c(
          "label",
          { staticClass: "d-block", attrs: { for: "excludeSellers" } },
          [_vm._v(" Exclude merchants from notification ")]
        ),
        _c(
          "el-select",
          {
            staticClass: "select-primary",
            attrs: {
              id: "excludeSellers",
              multiple: "",
              placeholder: "Select merchant",
              size: "small",
              clearable: "",
              filterable: "",
              "collapse-tags": "",
            },
            on: {
              clear: function ($event) {
                _vm.blacklist = []
              },
              change: _vm.updateOptions,
            },
            model: {
              value: _vm.blacklist,
              callback: function ($$v) {
                _vm.blacklist = $$v
              },
              expression: "blacklist",
            },
          },
          _vm._l(_vm.sellerList, function (option) {
            return _c("el-option", {
              key: option.seller,
              attrs: { value: option.seller, label: option.name },
            })
          }),
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "mb-4" }, [
      _c(
        "label",
        { staticClass: "mt-3 d-block", attrs: { for: "optionBlock" } },
        [_vm._v(" Get notified when: ")]
      ),
      _c(
        "div",
        { attrs: { id: "optionBlock" }, on: { change: _vm.updateOptions } },
        _vm._l(_vm.optionList, function (option) {
          return _c(
            "base-checkbox",
            {
              key: option.key,
              staticClass: "checkbox-success",
              model: {
                value: _vm.optionsSelected[option.key],
                callback: function ($$v) {
                  _vm.$set(_vm.optionsSelected, option.key, $$v)
                },
                expression: "optionsSelected[option.key]",
              },
            },
            [
              _vm._v(" " + _vm._s(option.title) + " "),
              option.tooltip
                ? _c(
                    "el-tooltip",
                    { attrs: { "open-delay": 500 } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { "max-width": "200px" },
                          attrs: { slot: "content" },
                          slot: "content",
                        },
                        [
                          _c("div", {
                            domProps: { innerHTML: _vm._s(option.tooltip) },
                          }),
                        ]
                      ),
                      _c("help-circle-icon", {
                        staticClass: "cursor-pointer rb-icon-sm text-muted",
                        attrs: { title: "" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }