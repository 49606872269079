var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "65px",
        title: _vm.dialogTitle,
        visible: _vm.show,
        "destroy-on-close": "",
        width: "50%",
        "custom-class": "dialog-primary dropdown-column-modal",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: { close: _vm.onCloseCancel },
    },
    [
      _c(
        "el-collapse",
        {
          staticClass: "mb-3",
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-collapse-item", { attrs: { title: "", name: "upload" } }, [
            _c(
              "div",
              { attrs: { slot: "title" }, slot: "title" },
              [
                _vm._v(" Adding multiple values? "),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: `Click to bulk upload multiple ${
                        _vm.field === "topic" ? "topic" : "column"
                      } values at once.`,
                      "open-delay": 300,
                    },
                  },
                  [
                    _c("info-icon", {
                      staticClass: "text-muted",
                      attrs: { title: "" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "p-2 bg-gray-light" },
              [
                _c(
                  "vue-dropzone",
                  {
                    ref: "bulkUploadColValues",
                    staticClass: "my-3",
                    attrs: {
                      id: "bulk-column-values",
                      options: _vm.dropzoneOptions,
                      "use-custom-slot": true,
                    },
                    on: {
                      "vdropzone-max-files-exceeded": _vm.onMaxFilesExceeded,
                      "vdropzone-success": _vm.onDropzoneSuccess,
                      "vdropzone-canceled": _vm.onDropzoneCancel,
                      "vdropzone-error": _vm.handleError,
                      "vdropzone-removed-file": _vm.onDropzoneCancel,
                    },
                  },
                  [
                    _c("div", { staticClass: "text-center text-muted" }, [
                      _c("p", { staticClass: "small mb-0" }, [
                        _vm._v(" Drag and drop your CSV file here, "),
                      ]),
                      _c("p", { staticClass: "small" }, [
                        _vm._v(
                          " or click to select a file from your computer. "
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm.fileUploadError
                  ? _c("el-alert", {
                      staticClass: "mb-3",
                      attrs: {
                        type: "error",
                        title: "Upload Error",
                        description: _vm.fileUploadError,
                        closable: false,
                        "show-icon": "",
                      },
                    })
                  : _vm._e(),
                _c("div", [
                  _c("h6", { staticClass: "mb-1" }, [
                    _vm._v(
                      " Get started adding multiple " +
                        _vm._s(_vm.field === "topic" ? "topic" : "column") +
                        " values: "
                    ),
                  ]),
                  _c("ol", { staticClass: "m-0" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "text-info p-0",
                            attrs: { type: "text" },
                            on: { click: _vm.onDownloadTemplate },
                          },
                          [
                            _vm._v(
                              " Download our template to help upload your " +
                                _vm._s(
                                  _vm.field === "topic" ? "topic" : "column"
                                ) +
                                " values. "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("li", [
                      _vm._v(
                        " For each row in your CSV, enter the " +
                          _vm._s(_vm.field === "topic" ? "topic" : "column") +
                          " value you wish to add. "
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        " Upload your CSV file by dragging and dropping into the box above or clicking and selecting your file. "
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        " Add or edit the parsed values from your CSV before saving your custom values. "
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "formColValues",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-position": "top",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSave.apply(null, arguments)
            },
          },
        },
        [
          _c("label", { staticClass: "el-form-item__label" }, [
            _vm._v(
              " " +
                _vm._s(_vm.field === "topic" ? "Topic" : "Column") +
                " values "
            ),
          ]),
          _vm._l(_vm.formModel.inputValues, function (item, index) {
            return _c("div", { key: index, staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col pr-0" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "mb-3",
                      attrs: {
                        prop: "inputValues." + index + ".value",
                        rules: {
                          required: true,
                          message: `${
                            _vm.field === "topic" ? "Topic" : "Column"
                          } value is required.`,
                          trigger: "change",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Enter value" },
                        model: {
                          value: item.value,
                          callback: function ($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-2 d-flex" },
                [
                  _vm.inputValuesLength > 1 || item.value
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: { "open-delay": 300, content: "Delete value" },
                        },
                        [
                          _c("el-button", {
                            staticClass: "align-self-start mt-1",
                            attrs: {
                              icon: "el-icon-close",
                              size: "mini",
                              type: "danger",
                              disabled: _vm.isLoading,
                              circle: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onDeleteValue(index)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  index >= _vm.inputValuesLength - 1
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            "open-delay": 300,
                            content: "Add additional value",
                          },
                        },
                        [
                          _c("el-button", {
                            staticClass: "align-self-start mt-1",
                            attrs: {
                              icon: "el-icon-plus",
                              size: "mini",
                              type: "primary",
                              disabled: _vm.isInvalid || _vm.isLoading,
                              circle: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onAddValue(false)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ])
          }),
        ],
        2
      ),
      _vm.field === "topic" && _vm.isInvalid && _vm.inputValuesLength <= 1
        ? [
            _c(
              "div",
              { staticClass: "d-flex flex-column align-items-center" },
              [
                _c("div", { staticClass: "mb-2" }, [
                  _vm._v(" You currently have no topic values. "),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.onAddValue(true)
                          },
                        },
                      },
                      [_vm._v(" Add default topic values ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
      _vm.isValidated && _vm.formError
        ? _c("el-alert", {
            attrs: {
              type: "error",
              title: "Invalid Input",
              description: _vm.formError,
              closable: false,
              "show-icon": "",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", loading: _vm.isLoading, plain: "" },
              on: { click: _vm.onConfirmCancel },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "el-tooltip",
            { attrs: { content: _vm.formError, disabled: !_vm.formError } },
            [
              _c(
                "el-button",
                {
                  class: { "is-disabled": _vm.isInvalid },
                  attrs: { type: "primary", loading: _vm.isLoading },
                  on: { click: _vm.onSave },
                },
                [_vm._v(" Save changes ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }