var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.updatesList && _vm.updatesList.length
    ? _c(
        "ul",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          staticClass: "list-group list-group-flush",
        },
        _vm._l(_vm.updatesList, function (update, index) {
          return _c(
            "li",
            {
              key: `response-item--${index}`,
              staticClass: "list-group-item update-container d-flex px-0",
            },
            [
              _c(
                "div",
                { staticClass: "update-badge pt-1 px-2" },
                [
                  _vm.updateIcon[update.type]
                    ? _c(_vm.updateIcon[update.type], {
                        tag: "component",
                        staticClass: "rb-icon-xl",
                        attrs: { title: "" },
                      })
                    : _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content:
                              update.badge === "Manufacturer"
                                ? "Manufacturer response"
                                : "Seller response",
                            "open-delay": 500,
                            disabled:
                              update.badge !== "Manufacturer" &&
                              update.badge !== "Seller",
                          },
                        },
                        [
                          _c("account-circle-icon", {
                            staticClass: "rb-icon-xl",
                            class: {
                              "text-primary": update.badge === "Manufacturer",
                              "text-info": update.badge === "Seller",
                            },
                            attrs: { title: "" },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c("div", { staticClass: "update-content flex-grow-1" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "update-header d-flex justify-content-between align-items-start mb-1",
                  },
                  [
                    _c("div", { staticClass: "update-author text-muted" }, [
                      update.badge === "Manufacturer"
                        ? _c("div", [
                            update.submitted_by
                              ? _c("div", [
                                  _c("span", [
                                    _c("span", [_vm._v("Submitted by")]),
                                    _vm._v(
                                      " " + _vm._s(update.submitted_by) + " | "
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getFormattedDate(
                                          update.submitted_ts
                                        )
                                      )
                                    ),
                                  ]),
                                ])
                              : update.posted_by
                              ? _c("div", [
                                  _c("span", [
                                    _c("span", [_vm._v("Posted by")]),
                                    _vm._v(
                                      " " + _vm._s(update.posted_by) + " | "
                                    ),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getFormattedDate(update.posted_ts)
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ])
                        : _c("div", [
                            _c("span", [
                              _vm._v(_vm._s(update.posted_by) + " | "),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.getFormattedDate(update.posted_ts))
                              ),
                            ]),
                          ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "header-actions" },
                      [
                        update.is_original_response && _vm.canEditResponse
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: "Remove response",
                                  "open-delay": 500,
                                },
                              },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title:
                                        "Are you sure you want remove your response?",
                                      "confirm-button-text":
                                        "Yes, remove response",
                                      "cancel-button-text": "No, cancel",
                                      "confirm-button-type": "danger",
                                      "cancel-button-type": "text text-dark",
                                      "hide-icon": "",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.onDeleteResponse(
                                          update,
                                          index
                                        )
                                      },
                                      cancel: () =>
                                        (_vm.isConfirmDeleteResponse = false),
                                    },
                                    model: {
                                      value: _vm.isConfirmDeleteResponse,
                                      callback: function ($$v) {
                                        _vm.isConfirmDeleteResponse = $$v
                                      },
                                      expression: "isConfirmDeleteResponse",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "btn-delete border-0",
                                        attrs: {
                                          slot: "reference",
                                          size: "mini",
                                          type: "light",
                                          circle: "",
                                        },
                                        slot: "reference",
                                      },
                                      [
                                        _c("delete-icon", {
                                          staticClass: "rb-icon-sm",
                                          attrs: { title: "" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        update.is_original_response && _vm.canEditResponse
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: "Edit response",
                                  "open-delay": 500,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "border-0",
                                    attrs: {
                                      size: "mini",
                                      type: "light",
                                      circle: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onEditResponse(update, index)
                                      },
                                    },
                                  },
                                  [
                                    _c("pencil-icon", {
                                      staticClass: "rb-icon-sm",
                                      attrs: { title: "" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _c("div", { staticClass: "border-response" }, [
                  _c("div", [
                    _vm.editResponse.index === index
                      ? _c(
                          "div",
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                rows: _vm.getNumberOfRows(
                                  _vm.editResponse.response
                                ),
                                autocomplete: "off",
                              },
                              model: {
                                value: _vm.editResponse.response,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editResponse, "response", $$v)
                                },
                                expression: "editResponse.response",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "edit-response-actions my-3 text-right",
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "border-0",
                                    attrs: {
                                      type: "muted",
                                      size: "small",
                                      plain: "",
                                    },
                                    on: { click: _vm.onCancelEditResponse },
                                  },
                                  [_vm._v(" Cancel ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "success", size: "small" },
                                    on: { click: _vm.onSaveEditResponse },
                                  },
                                  [_vm._v(" Update response ")]
                                ),
                              ],
                              1
                            ),
                            _vm.editResponse && _vm.editResponse.error
                              ? _c(
                                  "el-alert",
                                  {
                                    attrs: {
                                      title: "Response not updated",
                                      type: "error",
                                      "show-icon": "",
                                      closable: false,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.editResponse.error) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c("div", [_vm._v(" " + _vm._s(update.text) + " ")]),
                    update.alert
                      ? _c("p", { staticClass: "text-danger" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("showAlert")(update.alert)) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]
          )
        }),
        0
      )
    : _c(
        "div",
        {
          staticClass: "alert alert-light text-default",
          attrs: { role: "alert" },
        },
        [_vm._v(" No responses or updates found ")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }