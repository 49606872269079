var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-2" }, [
    _c("div", { staticClass: "bulk-input-container" }, [
      _vm.isReview
        ? _c(
            "div",
            { staticClass: "d-flex flex-column mb-3" },
            [
              _c("label", { attrs: { for: "bulk-sentiment-input" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.headerFieldNames["sentiment"] || "Sentiment") +
                    " "
                ),
              ]),
              _c(
                "el-select",
                {
                  attrs: {
                    id: "bulk-sentiment-input",
                    name: "sentiment-input",
                    filterable: "",
                    disabled: _vm.isLoading,
                  },
                  model: {
                    value: _vm.form.sentiment,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "sentiment", $$v)
                    },
                    expression: "form.sentiment",
                  },
                },
                _vm._l(_vm.sentimentOptions, function (option, optionIndex) {
                  return _c("el-option", {
                    key: optionIndex,
                    attrs: { label: option, value: option },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex flex-column mb-3" },
        [
          _c("label", { attrs: { for: "bulk-status-input" } }, [
            _vm._v(
              " " + _vm._s(_vm.headerFieldNames["status"] || "Status") + " "
            ),
          ]),
          _c(
            "el-select",
            {
              attrs: {
                id: "bulk-status-input",
                name: "status-input",
                filterable: "",
                disabled: _vm.isLoading,
              },
              model: {
                value: _vm.form.status,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "status", $$v)
                },
                expression: "form.status",
              },
            },
            _vm._l(_vm.statusOptions, function (option, optionIndex) {
              return _c("el-option", {
                key: optionIndex,
                attrs: { label: option, value: option },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-column mb-3" },
        [
          _c("label", { attrs: { for: "bulk-topic-input" } }, [
            _vm._v(
              " " + _vm._s(_vm.headerFieldNames["topic"] || "Topic") + " "
            ),
          ]),
          _c(
            "el-select",
            {
              staticClass: "select-primary",
              attrs: {
                id: "bulk-topic-input",
                name: "topic-input",
                filterable: "",
                multiple: "",
                clearable: "",
                "collapse-tags": "",
                disabled: _vm.isLoading,
              },
              model: {
                value: _vm.form.topic,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "topic", $$v)
                },
                expression: "form.topic",
              },
            },
            _vm._l(_vm.topicOptions, function (option, optionIndex) {
              return _c("el-option", {
                key: optionIndex,
                attrs: { label: option, value: option },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-column mb-3" },
        [
          _c("label", { attrs: { for: "bulk-custom-input" } }, [
            _vm._v(
              " " + _vm._s(_vm.headerFieldNames["customCol"] || "Custom") + " "
            ),
          ]),
          _c(
            "el-select",
            {
              staticClass: "select-primary",
              attrs: {
                id: "bulk-custom-input",
                name: "custom-input",
                filterable: "",
                multiple: "",
                clearable: "",
                "collapse-tags": "",
                disabled: _vm.isLoading,
              },
              model: {
                value: _vm.form.customCol,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "customCol", $$v)
                },
                expression: "form.customCol",
              },
            },
            _vm._l(_vm.customColOptions, function (option, optionIndex) {
              return _c("el-option", {
                key: optionIndex,
                attrs: { label: option, value: option },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "d-flex justify-content-end" },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-link text-muted text-decoration-none",
            attrs: { disabled: _vm.isLoading },
            on: { click: _vm.onCancel },
          },
          [_vm._v(" Cancel ")]
        ),
        _c("el-tooltip", { attrs: { "open-delay": 500 } }, [
          _c("div", { attrs: { slot: "content" }, slot: "content" }, [
            _vm.hasFormChanged
              ? _c("div", [
                  _vm._v(" Any changes made will affect all "),
                  _c("strong", [
                    _vm._v(
                      "selected " +
                        _vm._s(_vm.isReview ? "reviews" : "questions")
                    ),
                  ]),
                  _vm._v(". "),
                ])
              : _c("div", [
                  _vm._v(
                    " No changes have been made. Please change values in order to make updates to the selected " +
                      _vm._s(_vm.isReview ? "reviews" : "questions") +
                      ". "
                  ),
                ]),
          ]),
          _c("div", { staticClass: "ml-2 d-inline-block" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { disabled: _vm.isLoading || !_vm.hasFormChanged },
                on: { click: _vm.onSubmit },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.isLoading
                        ? "Saving..."
                        : `Update ${_vm.items.length} ${
                            _vm.isReview ? "reviews" : "questions"
                          }`
                    ) +
                    " "
                ),
              ]
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }