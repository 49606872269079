var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid pb-4" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "div",
          {
            staticClass:
              "col-12 search-term-filters d-flex align-items-center justify-content-between",
          },
          [
            _c(
              "div",
              { staticClass: "left-filter" },
              [
                _c("date-range-input", {
                  ref: "termDateRange",
                  staticClass: "term-date-range",
                  attrs: {
                    value: _vm.termDateRange,
                    label: "Date range",
                    disabled: _vm.isLoading || _vm.hasNoSearchTerm,
                  },
                  on: { "date-range-changed": _vm.onDateRangeChanged },
                  scopedSlots: _vm._u([
                    {
                      key: "label-tooltip",
                      fn: function () {
                        return [
                          _c(
                            "el-tooltip",
                            { attrs: { "open-delay": 500 } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { "max-width": "200px" },
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("p", [
                                    _vm._v(" The default date range is "),
                                    _c("strong", [_vm._v("Last 7 Days")]),
                                    _vm._v(". "),
                                  ]),
                                  _c("p", [
                                    _vm._v(" The start of a week is "),
                                    _c("strong", [_vm._v("Monday")]),
                                    _vm._v(
                                      '. Selecting a date range of "week" would be Monday to Sunday. '
                                    ),
                                  ]),
                                ]
                              ),
                              _c("info-icon", {
                                staticClass:
                                  "cursor-pointer text-muted rb-icon-sm",
                                attrs: { title: "" },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("div", { staticClass: "right-filter" }, [
              _c(
                "div",
                { staticClass: "d-flex flex-column" },
                [
                  _c("label", { attrs: { for: "term-count-input" } }, [
                    _vm._v(" Top terms "),
                  ]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        id: "term-count-input",
                        disabled: _vm.isLoading || _vm.hasNoSearchTerm,
                      },
                      model: {
                        value: _vm.termCount,
                        callback: function ($$v) {
                          _vm.termCount = $$v
                        },
                        expression: "termCount",
                      },
                    },
                    _vm._l(_vm.countOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
        _vm.hasNoSearchTerm
          ? [
              _c("div", { staticClass: "col-12 mt-3" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("h6", { staticClass: "card-title" }, [
                        _vm._v(" No search term found "),
                      ]),
                      _vm._m(0),
                      !_vm.isModal
                        ? [
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "el-button el-button--info el-button--small",
                                attrs: { to: "/searchbox/terms" },
                              },
                              [_vm._v(" Go to Searchbox Terms ")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "el-button el-button--info el-button--small is-plain",
                                attrs: { to: "/searchbox/products" },
                              },
                              [_vm._v(" Go to Searchbox Products ")]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
            ]
          : _vm._l(_vm.searchTermRanking, function (ranking) {
              return _c(
                "div",
                {
                  key: ranking.name + "--" + ranking.key,
                  class: {
                    "col-12": ranking.isHeading,
                    "col-12 col-xl-6 mb-3": !ranking.isHeading,
                  },
                },
                [
                  ranking.isHeading
                    ? _c(
                        "h5",
                        { staticClass: "my-3" },
                        [
                          _vm._v(" " + _vm._s(ranking.name) + " "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                "open-delay": 500,
                                content: _vm.headingTooltip(ranking.key),
                              },
                            },
                            [
                              _c("info-icon", {
                                staticClass: "text-muted cursor-pointer",
                                attrs: { title: "" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !ranking.isHeading
                    ? [
                        _c("div", { staticClass: "card" }, [
                          _c(
                            "div",
                            { staticClass: "card-body" },
                            [
                              _c("h6", { staticClass: "card-title" }, [
                                _vm._v(" " + _vm._s(ranking.name) + " "),
                              ]),
                              _vm.isLoading
                                ? _vm._l([1, 2, 3, 4, 5], function (skel, idx) {
                                    return _c(
                                      "div",
                                      {
                                        key: `loading-${ranking.name}--${idx}`,
                                        staticClass:
                                          "d-flex align-items-center mb-2",
                                      },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "skeleton skeleton-icon mr-2",
                                        }),
                                        _c("div", {
                                          staticClass:
                                            "skeleton skeleton-text w-75",
                                        }),
                                      ]
                                    )
                                  })
                                : [
                                    ranking.terms && ranking.terms.length
                                      ? _c(
                                          "ol",
                                          {
                                            staticClass:
                                              "list-group list-group-flush",
                                          },
                                          _vm._l(
                                            ranking.terms,
                                            function (term, idx) {
                                              return _c(
                                                "li",
                                                {
                                                  key: `${term.name}${ranking.key}--${idx}`,
                                                  staticClass:
                                                    "list-group-item d-flex px-0",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "list-number mr-1",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(idx + 1) +
                                                          ". "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "list-group-item-content w-100",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "product-name font-weight-bold mb-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                term.name
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex justify-content-between align-items-start",
                                                        },
                                                        [
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              attrs: {
                                                                content:
                                                                  "View product listing",
                                                                "open-delay": 500,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "small",
                                                                  attrs: {
                                                                    href: term.url,
                                                                    target:
                                                                      "_blank",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        term.listing
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _c(
                                                                    "sup",
                                                                    [
                                                                      _c(
                                                                        "external-link-icon",
                                                                        {
                                                                          staticClass:
                                                                            "rb-icon-xs",
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              attrs: {
                                                                content:
                                                                  _vm.pageRankTooltip(
                                                                    ranking.type
                                                                  ),
                                                                "open-delay": 500,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "badge rounded-pill bg-primary text-white text-capitalize mb-0",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Best Rank: " +
                                                                      _vm._s(
                                                                        term.best
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : [
                                          _c(
                                            "el-alert",
                                            {
                                              staticClass: "align-items-start",
                                              attrs: {
                                                title:
                                                  "No search rankings found",
                                                type: "info",
                                                closable: false,
                                                "show-icon": "",
                                              },
                                            },
                                            [
                                              ranking.type == "registered" &&
                                              ranking.key === "organic" &&
                                              _vm.all_unknown.length
                                                ? _c(
                                                    "p",
                                                    { staticClass: "mb-1" },
                                                    [
                                                      _vm._v(" A total of "),
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.all_unknown
                                                              .length
                                                          ) +
                                                            " organic listings"
                                                        ),
                                                      ]),
                                                      _vm._v(
                                                        " belong to competitor catalogs. "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              ranking.type == "registered" &&
                                              ranking.key === "sponsored" &&
                                              _vm.all_ad_unknown.length
                                                ? _c(
                                                    "p",
                                                    { staticClass: "mb-1" },
                                                    [
                                                      _vm._v(" A total of "),
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.all_ad_unknown
                                                              .length
                                                          ) +
                                                            " sponsored listings"
                                                        ),
                                                      ]),
                                                      _vm._v(
                                                        " belong to competitor catalogs. "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              ranking.type == "registered" &&
                                              ((ranking.key === "organic" &&
                                                _vm.all_unknown.length) ||
                                                (ranking.key === "sponsored" &&
                                                  _vm.all_ad_unknown.length))
                                                ? _c("p", [
                                                    _vm._v(
                                                      " With Searchbox, you can upload a list of search terms to be monitored. Visit our Help Center and read our guide on how to "
                                                    ),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "alert-link text-underline",
                                                        attrs: {
                                                          href: "https://helpcenter.getreviewbox.com/getting-started-with-searchbox",
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " get started with Searchbox. "
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                  ],
                            ],
                            2
                          ),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "card-text" }, [
      _vm._v(
        " With Searchbox, you can upload a list of search terms to be monitored. Visit our Help Center and read our guide on how to "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://helpcenter.getreviewbox.com/getting-started-with-searchbox",
            target: "_blank",
          },
        },
        [_vm._v(" get started with Searchbox. ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }