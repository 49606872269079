import NotificationsHelper from './NotificationsHelper';

class ReviewboxNotificationsHelper extends NotificationsHelper {
  constructor() {
    /**
     * Constructs reviewbox notifications object
     *
     */
    super();

    // Sets the notifications options

    this.notifications.options = {
      star_filter: 0,
    };

    // Star filter notification options
    this.starOptions = [
      { id: 0, text: 'No Filter' },
      { id: 1, text: 'Fewer than 2 stars' },
      { id: 2, text: 'Fewer than 3 stars' },
      { id: 3, text: 'Fewer than 4 stars' },
      { id: 4, text: 'Fewer than 5 stars' },
    ];
  }

  async getNotifications() {
    // Resolves or rejects Promise
    // populates constructor
    return this.getNotificationEndpoint()
      .then((response) => {
        const { data } = response;

        // Populate constructor only if there is data
        // otherwise use defaults
        if (data) {
          this.populateConstructor(data);
        }
      });
  }

  async postNotifications(payload) {
    // converts and posts the payload
    const outputDict = this.populatePost(payload);

    return this.postNotificationEndpoint(outputDict);
  }

  populateConstructor(data) {
    /**
     * @params {Object} populates response data into Object
     * format to populate class
     */

    /**
     * @todo Temporary key mapping to consume current API endpoint
     * @todo Endpoint to be refactored
     *
     */

    const translateDefs = {
      // key: 'api key'
      email: 'email',
      response_email: 'response_email',
      slack: 'slack',
      frequency: 'frequency',
    };

    const translateOptions = {
      star_filter: 'stars',
    };

    // Populate non-option fields
    Object.keys(translateDefs).forEach((key) => {
      const dataKey = translateDefs[key];
      // find each value in translateDefs in data
      if (Object.prototype.hasOwnProperty.call(data, dataKey)) {
        if (key === 'email' || key === 'response_email') {
          this.notifications[key] = this.listToArray(data[dataKey]);
        } else {
          this.notifications[key] = data[dataKey];
        }
      }
    });

    // Populate option fields
    Object.keys(translateOptions).forEach((key) => {
      const dataKey = translateOptions[key];
      // find each value in translateOptions in data
      if (Object.prototype.hasOwnProperty.call(data, dataKey)) {
        this.notifications.options[key] = data[dataKey];
      }
    });
  }

  populatePost(payload) {
    /**
     * @todo Temporary key mapping to consume current API endpoint
     * @todo Endpoint to be refactored
     *
     */

    const translateDefs = {
      // key: 'api key'
      email: 'email',
      response_email: 'response_email',
      slack: 'slack',
      frequency: 'frequency',
    };

    /**
     * @todo when consuming api_key is 'star', when posting param key is 'star_filter'
     */

    const translateOptions = {
      star_filter: 'star_filter',
    };

    const outputPayload = {};

    // Populate non-option fields
    Object.keys(translateDefs).forEach((key) => {
      const dataKey = translateDefs[key];
      // find each value in translateDefs in data
      if (key === 'email' || key === 'response_email') {
        outputPayload[dataKey] = this.arrayToList(payload[key]);
      } else {
        outputPayload[dataKey] = payload[key];
      }
    });

    // Populate option fields
    const { options } = payload;
    Object.keys(translateOptions).forEach((key) => {
      const dataKey = translateOptions[key];
      outputPayload[dataKey] = options[key];
    });

    // Stringify the output payload
    const jsonString = JSON.stringify(outputPayload);

    return { rb: jsonString };
  }
}

export default ReviewboxNotificationsHelper;
