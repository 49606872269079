var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c(
        "label",
        {
          staticClass: "d-block",
          attrs: { for: `frequency-input--${_vm.inputId}` },
        },
        [_vm._v(" Notification frequency ")]
      ),
      _c(
        "el-select",
        {
          attrs: {
            id: `frequency-input--${_vm.inputId}`,
            placeholder: "Select Frequency",
            size: "small",
          },
          on: { change: _vm.selectFrequency },
          model: {
            value: _vm.frequency,
            callback: function ($$v) {
              _vm.frequency = $$v
            },
            expression: "frequency",
          },
        },
        _vm._l(_vm.options, function (option) {
          return _c("el-option", {
            key: option.value,
            attrs: { value: option.value, label: option.title },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }