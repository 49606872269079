import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import NotificationsHelper from './NotificationsHelper';

class PriceboxNotificationsHelper extends NotificationsHelper {
  constructor() {
    /**
     * Constructs Pricebox notifications object
     *
     */
    super();

    // Sets the default notifications options
    this.defaultOptions = {
      new_stock: false,
      new_map: false,
      new_sellers: false,
      new_buybox: false,
      redirects: false,
      seller_to_exclude: null,
      blacklist: [],
      merchant: null,
    };

    this.notifications.options = cloneDeep(this.defaultOptions);

    // Default list for blacklist
    /**
     * @todo sellers received from GET request may differ from default
     */
    this.sellers = [
      {
        seller: 'amazon.com',
        name: 'Amazon (US)',
      },
      {
        seller: 'amazon.ca',
        name: 'Amazon (Canada)',
      },
      {
        seller: 'amazon.mx',
        name: 'Amazon (Mexico)',
      },
      {
        seller: 'amazon.uk',
        name: 'Amazon (UK)',
      },
      {
        seller: 'amazon.de',
        name: 'Amazon (Germany)',
      },
      {
        seller: 'amazon.fr',
        name: 'Amazon (France)',
      },
      {
        seller: 'amazon.it',
        name: 'Amazon (Italy)',
      },
      {
        seller: 'amazon.es',
        name: 'Amazon (Spain)',
      }];
  }

  async getNotifications() {
    // Resolves or rejects Promise
    // populates constructor
    return this.getNotificationEndpoint()
      .then((response) => {
        const { data } = response;
        const { alerts, sellers } = data;
        this.populateConstructor(alerts, sellers);
      });
  }

  async postNotifications(payload) {
    // converts and posts the payload
    // Must pass this.sellers as param since it is a nested function
    const outputDict = this.populatePost(payload, this.sellers);

    return this.postNotificationEndpoint(outputDict);
  }

  setSellers(sellers) {
    /**
     * @params {Array} populates response data for sellers into
     * Array of objects
     */

    this.sellers = sellers;
  }

  /**
   * @params {Object} populates response data into Object
   * format to populate class
   *
   * @todo WARNING: Temporary key mapping to consume current API endpoint
   * Endpoint to be refactored. We have to do extra parsing for pricebox notifications,
   * need to refactor so it is ready to go from api.
   */
  populateConstructor(alerts, sellers) {
    // Pricebox notifications are returned as an object of 'alerts'

    // modify default sellers
    this.setSellers(sellers);

    // Reset options
    this.notifications.options = cloneDeep(this.defaultOptions);

    // Skip if alerts doesn't exist
    if (isEmpty(alerts)) return;

    const translateDefs = {
      // key: 'api key'
      email: 'emails',
      slack: 'slack',
      frequency: 'frequency',
    };

    const translateOptions = {
      new_prices: 'newprices',
      new_stock: 'newstock',
      new_map: 'newmap',
      new_sellers: 'newsellers',
      new_buybox: 'newbuybox',
      merchant: 'merchant',
      redirects: 'redirects',
    };

    // Populate non-option fields
    Object.keys(translateDefs).forEach((key) => {
      const dataKey = translateDefs[key];
      // find each value in translateDefs in data
      if (Object.prototype.hasOwnProperty.call(alerts, dataKey)) {
        // Pricebox returns an array of emails
        this.notifications[key] = alerts[dataKey];
      }
    });

    // Populate option fields
    Object.keys(translateOptions).forEach((key) => {
      const dataKey = translateOptions[key];
      // find each value in translateOptions in data
      if (Object.prototype.hasOwnProperty.call(alerts, dataKey)) {
        this.notifications.options[key] = alerts[dataKey];
      }
    });

    // Get Seller Blacklist
    if (Object.prototype.hasOwnProperty.call(alerts, 'blacklist')) {
      const { blacklist } = alerts;
      blacklist.forEach((listing) => {
        // Pushes seller into blacklist
        this.notifications.options.blacklist.push(listing.seller);
      });
    }
  }

  populatePost(payload) {
    /**
     * @Params {Object, Array} Must pass payload and sellerList in order
     * to ensure sellerList can be found with this
     *
     * @todo: Temporary key mapping to POST to current API endpoint
     * Endpoint to be refactored
     */

    const translateDefs = {
      // key: 'api key'
      email: 'pbemail',
      slack: 'pbslack',
      frequency: 'pbfrequency',
    };

    // key: 'api key'
    const translateOptions = {
      new_stock: 'new_stock',
      new_map: 'new_map',
      new_sellers: 'new_sellers',
      new_buybox: 'new_buybox',
      redirects: 'redirects',
    };

    const outputPayload = {};

    // Populate non-option fields
    Object.keys(translateDefs).forEach((key) => {
      const dataKey = translateDefs[key];
      // find each value in translateDefs in data
      /**
       * @todo email payload on POST is in string format
       */
      if (key === 'email') {
        outputPayload[dataKey] = this.arrayToList(payload[key]);
      } else {
        outputPayload[dataKey] = payload[key];
      }
    });

    // Populate option fields
    const { options } = payload;
    Object.keys(translateOptions).forEach((key) => {
      const dataKey = translateOptions[key];
      outputPayload[dataKey] = options[key];
    });

    // Populate merchant field seperately if not null
    if (options.merchant !== null) {
      outputPayload.merchant = options.merchant;
    }

    // Blacklist must be an array of dicts with 'name' and 'seller'
    const outputBlacklist = [];
    // Nested loop must be used to unpack both arrays
    options.blacklist.forEach((key) => {
      this.sellers.forEach((seller) => {
        if (seller.seller === key) {
          outputBlacklist.push({
            seller: key,
            name: seller.name,
          });
        }
      });
    });

    outputPayload.blacklist = outputBlacklist;

    // Stringify the output payload
    const jsonString = JSON.stringify(outputPayload);

    return { pb: jsonString };
  }
}

export default PriceboxNotificationsHelper;
