import NotificationsHelper from './NotificationsHelper';

class SearchboxNotificationsHelper extends NotificationsHelper {
  constructor() {
    /**
     * Constructs searchbox notifications object
     *
     */
    super();

    // Searchbox frequency only has weekly and monthly

    this.notifications.frequency = 'weekly';

    // Searchbox has no options
  }

  async getNotifications() {
    // Resolves or rejects Promise
    // populates constructor
    return this.getNotificationEndpoint()
      .then((response) => {
        const { data } = response;

        // Populate constructor only if there is data
        // otherwise use defaults
        if (data) {
          this.populateConstructor(data);
        }
      });
  }

  async postNotifications(payload) {
    // converts and posts the payload
    const outputDict = this.populatePost(payload);

    return this.postNotificationEndpoint(outputDict);
  }

  populateConstructor(data) {
    /**
     * @params {Object} populates response data into Object
     * format to populate class
     */

    /**
     * @todo Temporary key mapping to consume current API endpoint
     * @todo Endpoint to be refactored
     *
     */

    const translateDefs = {
      // key: 'api key'
      email: 'sbemails',
      frequency: 'sbfrequency',
    };

    // Populate non-option fields
    Object.keys(translateDefs).forEach((key) => {
      const dataKey = translateDefs[key];
      // find each value in translateDefs in data
      if (Object.prototype.hasOwnProperty.call(data, dataKey)) {
        if (key === 'email') {
          this.notifications[key] = this.listToArray(data[dataKey]);
        } else {
          this.notifications[key] = data[dataKey];
        }
      }
    });
  }

  populatePost(payload) {
    /**
     * @todo Temporary key mapping to consume current API endpoint
     * @todo Endpoint to be refactored
     * @todo email from GET is 'sbemails' email on post is 'sbemail'
     *
     */

    const translateDefs = {
      // key: 'api key'
      email: 'sbemail',
      frequency: 'sbfrequency',
    };

    const outputPayload = {};

    // Populate non-option fields
    Object.keys(translateDefs).forEach((key) => {
      const dataKey = translateDefs[key];
      // find each value in translateDefs in data
      if (key === 'email') {
        outputPayload[dataKey] = this.arrayToList(payload[key]);
      } else {
        outputPayload[dataKey] = payload[key];
      }
    });

    // Stringify the output payload
    const jsonString = JSON.stringify(outputPayload);

    return { sb: jsonString };
  }
}

export default SearchboxNotificationsHelper;
