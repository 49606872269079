var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "mb-4" },
      [
        _c(
          "label",
          { staticClass: "d-block", attrs: { for: "notificationType" } },
          [_vm._v(" Notification type ")]
        ),
        _c(
          "el-select",
          {
            attrs: {
              id: "notificationType",
              placeholder: "Select notification type",
              size: "small",
            },
            on: { change: _vm.updateOptions },
            model: {
              value: _vm.type,
              callback: function ($$v) {
                _vm.type = $$v
              },
              expression: "type",
            },
          },
          _vm._l(_vm.typeList, function (option) {
            return _c("el-option", {
              key: option.key,
              attrs: { value: option.key, label: option.title },
            })
          }),
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "mb-4" }, [
      _c("label", { staticClass: "d-block", attrs: { for: "optionBlock" } }, [
        _vm._v(" Be notified when: "),
      ]),
      _c(
        "div",
        { attrs: { id: "optionBlock" }, on: { change: _vm.updateOptions } },
        [
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.optionList, function (option) {
              return _c(
                "div",
                { key: option.key, staticClass: "col-md-6" },
                [
                  _c(
                    "base-checkbox",
                    {
                      model: {
                        value: _vm.optionsSelected[option.key],
                        callback: function ($$v) {
                          _vm.$set(_vm.optionsSelected, option.key, $$v)
                        },
                        expression: "optionsSelected[option.key]",
                      },
                    },
                    [_vm._v(" " + _vm._s(option.title) + " ")]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }