var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        attrs: { novalidate: "", "data-vv-scope": _vm.formName },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.validateForm(_vm.service)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "card-body pb-0" },
          [
            _c("EmailComponent", {
              attrs: {
                "initial-emails": _vm.notificationSettings.email,
                "form-name": _vm.formName,
              },
              on: { "update:email": _vm.updateFields },
            }),
            _vm.service === "reviewbox"
              ? _c("EmailComponent", {
                  attrs: {
                    "initial-emails": _vm.notificationSettings.response_email,
                    "is-response-email": true,
                    "form-name": _vm.formName,
                    tooltip:
                      "A notification will be sent whenever a customer responds to a question on Amazon",
                  },
                  on: { "update:response_email": _vm.updateFields },
                })
              : _vm._e(),
            _vm.showFrequency
              ? _c("FrequencyComponent", {
                  attrs: {
                    "initial-frequency": _vm.notificationSettings.frequency,
                    service: _vm.service,
                    "form-name": _vm.formName,
                  },
                  on: { "update:frequency": _vm.updateFields },
                })
              : _vm._e(),
            _vm.showSlack
              ? _c("SlackComponent", {
                  attrs: {
                    "initial-slack": _vm.notificationSettings.slack,
                    "form-name": _vm.formName,
                  },
                  on: { "update:slack": _vm.updateFields },
                })
              : _vm._e(),
            _vm._t("default"),
            !_vm.isFormValid || _vm.formError
              ? _c(
                  "el-alert",
                  {
                    staticClass: "mb-4",
                    attrs: { type: "error", closable: false },
                  },
                  [
                    _c(
                      "h6",
                      {
                        staticClass: "alert-heading d-flex align-items-center",
                      },
                      [
                        _c("close-circle-icon", {
                          staticClass: "mr-1",
                          attrs: { title: "" },
                        }),
                        _vm._v(" Invalid Form "),
                      ],
                      1
                    ),
                    _vm.formError
                      ? _c("p", [_vm._v(" " + _vm._s(_vm.formError) + " ")])
                      : _c("p", [
                          _vm._v(
                            " One or more form inputs is incorrect. Please update and try again. "
                          ),
                        ]),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "d-flex pt-3 px-3 border-top" },
          [
            _vm.showTestNotification
              ? _c(
                  "el-tooltip",
                  {
                    attrs: { "open-delay": 500 },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _vm._v(
                                " Click to preview an email notification for "
                              ),
                              _c("span", { staticClass: "text-capitalize" }, [
                                _vm._v(_vm._s(_vm.service)),
                              ]),
                              _vm._v(
                                ". You will receive an email notification to the email(s) you entered above. "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1865539526
                    ),
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          plain: "",
                          "native-type": "button",
                          size: "small",
                          disabled: _vm.loading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("test-notification", _vm.service)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("send-icon", {
                              staticClass: "rb-icon-sm mr-2",
                              attrs: { title: "" },
                            }),
                            _vm._v(" Test notification "),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-tooltip",
              {
                staticClass: "ml-auto",
                attrs: { "open-delay": 500 },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _vm._v(" Update changes to the "),
                        _c("span", { staticClass: "text-capitalize" }, [
                          _vm._v(_vm._s(_vm.service)),
                        ]),
                        _vm._v(" email notifications settings "),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      "native-type": "submit",
                      size: "small",
                      loading: _vm.loading,
                    },
                  },
                  [_vm._v(" Update settings ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }