var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "catalog-management-container" },
    [
      _c("collapse-header", {
        staticClass: "mb-2",
        attrs: { title: "Catalog Management", loading: _vm.isLoading },
      }),
      !_vm.hasUserPlan
        ? _c("div", { staticClass: "row mb-2" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("el-alert", {
                  attrs: {
                    title: "User Plan Not Found",
                    description:
                      "Please refresh the page, log out, or contact support if this issue persists.",
                    type: "warning",
                    closable: false,
                    "show-icon": "",
                  },
                }),
              ],
              1
            ),
          ])
        : [
            _c(
              "div",
              {
                staticClass: "row catalog-manager-toolbar mb-3 align-items-end",
              },
              [
                _c("div", { staticClass: "col-12 col-lg-3 mb-2 mb-lg-0" }, [
                  _c(
                    "div",
                    { staticClass: "listing-heading d-flex flex-column" },
                    [
                      _c("label", { attrs: { for: "user-active-catalog" } }, [
                        _vm._v(" Active listings for "),
                      ]),
                      _c(
                        "el-select",
                        {
                          staticClass: "el-select--transparent",
                          staticStyle: { "max-width": "200px" },
                          attrs: { id: "user-active-catalog", size: "small" },
                          model: {
                            value: _vm.selectedUser,
                            callback: function ($$v) {
                              _vm.selectedUser = $$v
                            },
                            expression: "selectedUser",
                          },
                        },
                        _vm._l(_vm.userChoices, function (user, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: user, value: user },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "col-12 col-lg-9 text-lg-right" },
                  _vm._l(_vm.addItemsLinks, function (item, index) {
                    return _c(
                      "el-dropdown",
                      {
                        key: `catalog-dropdown--${index}`,
                        staticClass: "ml-lg-2 mr-2 mr-lg-0",
                        attrs: { trigger: "click" },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: item.btnTooltip,
                              placement: "top",
                              disabled: !item.btnTooltip,
                              "open-delay": 700,
                            },
                          },
                          [
                            _c(
                              "span",
                              [
                                _c(
                                  "el-button",
                                  {
                                    class: {
                                      disabled: !_vm.isServiceSubscribed(
                                        item.service
                                      ),
                                    },
                                    attrs: {
                                      size: "small",
                                      type: item.btnType ? item.btnType : null,
                                      plain: item.btnPlain,
                                      icon: item.btnIcon,
                                      disabled: !_vm.isServiceSubscribed(
                                        item.service
                                      ),
                                    },
                                  },
                                  [
                                    !item.btnIcon
                                      ? [
                                          _vm._v(
                                            " " + _vm._s(item.title) + " "
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-arrow-down el-icon--right",
                                          }),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _vm._l(item.children, function (link) {
                              return [
                                _vm.isServiceSubscribed(link.service)
                                  ? _c(
                                      "router-link",
                                      {
                                        key: link.title,
                                        attrs: { to: link.to },
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            class: {
                                              "is-delete":
                                                link.icon &&
                                                link.icon === "delete",
                                            },
                                            attrs: { divided: link.divided },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                              },
                                              [
                                                link.icon
                                                  ? _c(`${link.icon}-icon`, {
                                                      tag: "component",
                                                      staticClass: "mr-2",
                                                      class: {
                                                        "delete-icon":
                                                          link.icon &&
                                                          link.icon ===
                                                            "delete",
                                                      },
                                                      attrs: { title: "" },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(
                                                  " " + _vm._s(link.title) + " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "el-dropdown-item",
                                      {
                                        key: link.title,
                                        staticClass: "disabled",
                                        attrs: {
                                          divided: link.divided,
                                          disabled: "",
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(link.title) + " ")]
                                    ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "row mb-3" },
              _vm._l(_vm.totalActiveListings, function (value, key) {
                return _c(
                  "div",
                  {
                    key: key,
                    staticClass: "col-12 col-lg-4 col-xl mb-3 mb-xl-0",
                  },
                  [
                    _c("catalog-service-card", {
                      staticClass: "cursor-pointer h-100",
                      attrs: {
                        title: key === "searchterms" ? "Search Terms" : key,
                        "listing-limit": value.limit,
                        "listing-active": _vm.activeListingCount(value, key),
                        "listing-total":
                          _vm.userChoices.length > 2 &&
                          _vm.selectedUser &&
                          _vm.selectedUser !== "All"
                            ? _vm.activeListingCount(value, key, true)
                            : _vm.userChoices.length > 1 &&
                              _vm.selectedUser &&
                              _vm.selectedUser !== "All" &&
                              _vm.selectedUser !== _vm.userPlan.master
                            ? _vm.activeListingCount(value, key, true)
                            : null,
                        "listing-selected": _vm.selectedUser,
                        "listing-service": key,
                        "catalog-updates": _vm.catalogCount[key],
                        icon: _vm.serviceIcon(key),
                        loading: _vm.isLoading,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return ((ev) =>
                            _vm.handleStatsClick(
                              ev,
                              key,
                              value.by_source
                            )).apply(null, arguments)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c(
                    "el-tabs",
                    {
                      staticClass: "rb-catalog-tabs",
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            label: "Custom Fields",
                            name: "custom-fields",
                            lazy: "",
                          },
                        },
                        [_c("custom-fields")],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            label: "Archived Listings",
                            name: "archived-listings",
                            lazy: "",
                          },
                        },
                        [_c("archived-listings")],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            label: "Archived Terms",
                            name: "archived-terms",
                            lazy: "",
                          },
                        },
                        [_c("archived-terms")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
      _c(
        "el-dialog",
        {
          attrs: {
            top: "65px",
            visible: _vm.showModal,
            "destroy-on-close": "",
            width: "75%",
            "custom-class": "dialog-secondary",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showModal = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "el-dialog__title text-capitalize",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v(" " + _vm._s(_vm.listingsBreakdown.service) + " ")]
          ),
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.listingsBreakdown.data || [],
                    "default-sort": { prop: "count", order: "descending" },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "source", label: "Source", sortable: "" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "count", label: "Count", sortable: "" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer text-right",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.showModal = false
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }