var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-md-10 ml-0 pl-0" },
    [
      _c("base-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: _vm.modelValidations.url,
            expression: "modelValidations.url",
          },
        ],
        attrs: {
          error: _vm.getError(`${_vm.formName}.slack`),
          label: "Slack webhook",
          name: "slack",
          type: "url",
          placeholder: "Enter Slack webhook",
        },
        on: { change: _vm.emitChange },
        model: {
          value: _vm.slack,
          callback: function ($$v) {
            _vm.slack = $$v
          },
          expression: "slack",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }