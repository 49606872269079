var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "notification-settings" } },
    [
      _c("collapse-header", {
        attrs: {
          title: "Email Notification Settings",
          "help-text": "How does this page work?",
          loading: _vm.isLoading,
        },
        scopedSlots: _vm._u([
          {
            key: "help",
            fn: function () {
              return [_c("notifications-help-button")]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.isLoading
        ? _c("div", { staticClass: "row mt-3" }, [
            _c(
              "div",
              {
                staticClass: "col-12 col-xl-8",
                staticStyle: { "max-width": "1200px" },
              },
              _vm._l(5, function (n, key) {
                return _c("div", { key: key, staticClass: "card mb-3" }, [
                  _vm._m(0, true),
                  _vm._m(1, true),
                ])
              }),
              0
            ),
          ])
        : _c(
            "section",
            { staticClass: "rb-notification-settings-section mt-3 row" },
            [
              _c(
                "div",
                {
                  staticClass: "col-12 col-xl-8",
                  staticStyle: { "max-width": "1200px" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            "open-delay": 500,
                            content:
                              "Click to preview all email notifications. You will receive an email notifications to the email(s) address you've entered.",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", plain: "" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.testNotifications(null)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("send-icon", {
                                    staticClass: "rb-icon-sm mr-2",
                                    attrs: { title: "" },
                                  }),
                                  _vm._v(" Test all notifications "),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-collapse",
                        {
                          model: {
                            value: _vm.activeItem,
                            callback: function ($$v) {
                              _vm.activeItem = $$v
                            },
                            expression: "activeItem",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            { staticClass: "mb-3", attrs: { name: "1" } },
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("cog-icon", { tag: "component" }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v("Reviewbox Notifications"),
                                  ]),
                                ],
                                1
                              ),
                              _vm.reviewboxNotifications.notifications
                                ? _c(
                                    "SetNotifications",
                                    {
                                      key: _vm.resetKey,
                                      attrs: {
                                        "notification-settings":
                                          _vm.reviewboxNotifications
                                            .notifications,
                                        service: "reviewbox",
                                        loading: _vm.isSubmitting,
                                        "form-error": _vm.formError.reviewbox,
                                      },
                                      on: {
                                        "update:notifications": function (
                                          $event
                                        ) {
                                          return _vm.updateNotifications(
                                            "reviewbox",
                                            $event
                                          )
                                        },
                                        "test-notification":
                                          _vm.testNotifications,
                                        "submit-settings": _vm.onSubmit,
                                      },
                                    },
                                    [
                                      _vm.reviewboxNotifications.notifications
                                        ? _c("ReviewboxOptions", {
                                            key: _vm.resetKey,
                                            attrs: {
                                              "initial-filter":
                                                _vm.reviewboxNotifications
                                                  .notifications.options
                                                  .star_filter,
                                              "star-options":
                                                _vm.reviewboxNotifications
                                                  .starOptions,
                                            },
                                            on: {
                                              "update:filter": function (
                                                $event
                                              ) {
                                                return _vm.updateOptions(
                                                  "reviewbox",
                                                  $event
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "el-collapse-item",
                            { staticClass: "mb-3", attrs: { name: "2" } },
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("cog-icon", { tag: "component" }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v("Pricebox Notifications"),
                                  ]),
                                ],
                                1
                              ),
                              _vm.priceboxNotifications.notifications
                                ? _c(
                                    "SetNotifications",
                                    {
                                      key: _vm.pbResetKey,
                                      attrs: {
                                        "notification-settings":
                                          _vm.priceboxNotifications
                                            .notifications,
                                        service: "pricebox",
                                        "form-error": _vm.formError.pricebox,
                                        loading: _vm.isSubmitting,
                                      },
                                      on: {
                                        "update:notifications": function (
                                          $event
                                        ) {
                                          return _vm.updateNotifications(
                                            "pricebox",
                                            $event
                                          )
                                        },
                                        "test-notification":
                                          _vm.testNotifications,
                                        "submit-settings": _vm.onSubmit,
                                      },
                                    },
                                    [
                                      _vm.priceboxNotifications.notifications
                                        ? _c("PriceboxOptions", {
                                            key: _vm.pbResetKey,
                                            attrs: {
                                              "initial-options":
                                                _vm.priceboxNotifications
                                                  .notifications.options,
                                              "seller-list":
                                                _vm.priceboxNotifications
                                                  .sellers,
                                            },
                                            on: {
                                              "update:pboptions": function (
                                                $event
                                              ) {
                                                return _vm.updateOptions(
                                                  "pricebox",
                                                  $event
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "el-collapse-item",
                            { staticClass: "mb-3", attrs: { name: "3" } },
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("cog-icon", { tag: "component" }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v("Copybox Notifications"),
                                  ]),
                                ],
                                1
                              ),
                              _vm.copyboxNotifications.notifications
                                ? _c(
                                    "SetNotifications",
                                    {
                                      key: _vm.cbResetKey,
                                      attrs: {
                                        "notification-settings":
                                          _vm.copyboxNotifications
                                            .notifications,
                                        service: "copybox",
                                        "form-error": _vm.formError.copybox,
                                        loading: _vm.isSubmitting,
                                      },
                                      on: {
                                        "update:notifications": function (
                                          $event
                                        ) {
                                          return _vm.updateNotifications(
                                            "copybox",
                                            $event
                                          )
                                        },
                                        "test-notification":
                                          _vm.testNotifications,
                                        "submit-settings": _vm.onSubmit,
                                      },
                                    },
                                    [
                                      _vm.copyboxNotifications.notifications
                                        ? _c("CopyboxOptions", {
                                            key: _vm.cbResetKey,
                                            attrs: {
                                              "initial-options":
                                                _vm.copyboxNotifications
                                                  .notifications.options,
                                            },
                                            on: {
                                              "update:cboptions": function (
                                                $event
                                              ) {
                                                return _vm.updateOptions(
                                                  "copybox",
                                                  $event
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "el-collapse-item",
                            { staticClass: "mb-3", attrs: { name: "4" } },
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("cog-icon", { tag: "component" }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v("Searchbox Notifications"),
                                  ]),
                                ],
                                1
                              ),
                              _vm.searchboxNotifications.notifications
                                ? _c("SetNotifications", {
                                    key: _vm.sbResetKey,
                                    attrs: {
                                      "notification-settings":
                                        _vm.searchboxNotifications
                                          .notifications,
                                      "show-slack": false,
                                      service: "searchbox",
                                      "form-error": _vm.formError.searchbox,
                                      loading: _vm.isSubmitting,
                                    },
                                    on: {
                                      "update:notifications": function (
                                        $event
                                      ) {
                                        return _vm.updateNotifications(
                                          "searchbox",
                                          $event
                                        )
                                      },
                                      "test-notification":
                                        _vm.testNotifications,
                                      "submit-settings": _vm.onSubmit,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "el-collapse-item",
                            { staticClass: "mb-3", attrs: { name: "5" } },
                            [
                              _c(
                                "template",
                                { slot: "title" },
                                [
                                  _c("cog-icon", { tag: "component" }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v("AMS Notifications"),
                                  ]),
                                ],
                                1
                              ),
                              _vm.amsNotifications.notifications
                                ? _c("SetNotifications", {
                                    key: _vm.amsResetKey,
                                    attrs: {
                                      "notification-settings":
                                        _vm.amsNotifications.notifications,
                                      "show-slack": false,
                                      "show-frequency": false,
                                      "show-test-notification": false,
                                      service: "ams",
                                      "form-error": _vm.formError.ams,
                                      loading: _vm.isSubmitting,
                                    },
                                    on: {
                                      "update:notifications": function (
                                        $event
                                      ) {
                                        return _vm.updateNotifications(
                                          "ams",
                                          $event
                                        )
                                      },
                                      "submit-settings": _vm.onSubmit,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header pb-3 border-bottom" }, [
      _c("h4", { staticClass: "skeleton skeleton-title card-title mb-0" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-body" }, [
      _c("p", { staticClass: "skeleton skeleton-text" }),
      _c("p", { staticClass: "skeleton skeleton-text" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }