var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      staticClass: "rb-response-drawer",
      class: { "is-fullscreen": _vm.isFullscreen },
      attrs: {
        visible: _vm.showResponseDrawer,
        size: _vm.isFullscreen ? "100%" : "45vw",
        modal: false,
        "with-header": false,
        "wrapper-closable": false,
        "destroy-on-close": "",
        "close-on-press-escape": "",
      },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column", staticStyle: { height: "100vh" } },
        [
          _c(
            "response-drawer-toolbar",
            {
              attrs: {
                "is-review": _vm.isReview,
                "is-amazon": _vm.isAmazon,
                "is-loading": _vm.isLoading,
                "is-fullscreen": _vm.isFullscreen,
                "show-response-drawer": _vm.showResponseDrawer,
                "has-enterprise": _vm.hasEnterprise,
              },
              on: {
                vote: function ($event) {
                  return _vm.submitSocialAction("vote")
                },
                abuse: function ($event) {
                  return _vm.submitSocialAction("abuse")
                },
                fullscreen: function ($event) {
                  return _vm.onOpenFullscreen(!_vm.isFullscreen)
                },
                prev: _vm.onPrevRow,
                next: _vm.onNextRow,
                close: _vm.onResponseDrawerClosed,
              },
            },
            [
              _vm.responseData && _vm.responseData.url
                ? _c(
                    "a",
                    {
                      staticClass:
                        "el-button el-button--default el-button--mini is-plain ml-3",
                      attrs: {
                        slot: "left-actions",
                        href: _vm.responseData.url,
                        target: "_blank",
                      },
                      slot: "left-actions",
                    },
                    [
                      _vm._v(
                        " Go to " + _vm._s(_vm.reviewQuestionTextLabel()) + " "
                      ),
                      _c("external-link-icon", {
                        staticClass: "rb-icon-xs",
                        attrs: { title: "" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm.isLoading
            ? _c("div", { staticClass: "container-fluid flex-grow-1" }, [
                _c("div", { staticClass: "row h-100" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-8 body-content d-flex align-items-center justify-content-center h-100 py-3",
                    },
                    [_c("loading-panel")],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-4 response-side-panel py-3 bg-gray-light",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "product-information d-flex align-items-center pb-3 border-bottom mb-2",
                        },
                        [
                          _c("div", {
                            staticClass: "skeleton mr-3",
                            staticStyle: { width: "50px", height: "50px" },
                          }),
                          _c("div", {
                            staticClass: "skeleton skeleton-text w-50",
                            staticStyle: { height: "1rem" },
                          }),
                        ]
                      ),
                      _vm._l([1, 2, 3, 4, 5, 6], function (item, idx) {
                        return _c(
                          "div",
                          {
                            key: `panel-skeleton--${idx}`,
                            staticClass: "d-flex flex-column mb-3",
                          },
                          [
                            _c("div", {
                              staticClass: "skeleton skeleton-text mb-2 w-50",
                            }),
                            _c("div", {
                              staticClass: "skeleton skeleton-text",
                              staticStyle: { height: "1.5rem" },
                            }),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "response-drawer-footer bg-white" },
                    [
                      _c("div", {
                        staticClass: "skeleton skeleton-text",
                        staticStyle: { height: "2rem", "border-radius": "5px" },
                      }),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.isLoadingFailed
            ? _c(
                "div",
                { staticClass: "container-fluid" },
                [
                  _c(
                    "el-alert",
                    {
                      staticClass: "mt-3",
                      attrs: {
                        title: `Could not get ${_vm.reviewQuestionTextLabel()} data`,
                        closable: false,
                        "show-icon": "",
                      },
                    },
                    [_vm._v(" Please try again in a moment. ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isLoading && !_vm.isLoadingFailed
            ? _c(
                "div",
                {
                  staticClass:
                    "response-drawer-body container-fluid flex-grow-1",
                },
                [
                  _c("div", { staticClass: "row h-100" }, [
                    _c("div", { staticClass: "col-8 body-content py-3" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "review-container rb-response-management-content",
                        },
                        [
                          _c("div", { staticClass: "review-wrapper mb-3" }, [
                            _vm.data && _vm.data.title
                              ? _c(
                                  "h5",
                                  { staticClass: "review-title mb-1" },
                                  [
                                    _c("truncate-text", {
                                      attrs: {
                                        text: _vm.data.title,
                                        limit: 100,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "review-subtitle-wrapper mb-2" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "review-subtitle text-muted d-flex align-items-center flex-wrap",
                                  },
                                  [
                                    _vm.data && _vm.data.author
                                      ? _c(
                                          "span",
                                          { staticClass: "review-author" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.data.author) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.formattedReviewDate
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center",
                                          },
                                          [
                                            _vm.data && _vm.data.author
                                              ? _c("span", {
                                                  staticClass:
                                                    "text-divider mx-2",
                                                })
                                              : _vm._e(),
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.formattedReviewDate
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.responseTrackingInfo &&
                                    _vm.responseTrackingInfo.stars
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center",
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "text-divider mx-2",
                                            }),
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  content: `Review rating of ${_vm.responseTrackingInfo.stars}`,
                                                  "open-delay": 500,
                                                },
                                              },
                                              [
                                                _c("review-rating-bar", {
                                                  staticClass:
                                                    "d-inline-flex align-items-center h-100",
                                                  attrs: {
                                                    "number-stars":
                                                      _vm.responseTrackingInfo
                                                        .stars,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm.numberStars
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center",
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "text-divider mx-2",
                                            }),
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  content: `Review rating of ${_vm.numberStars}`,
                                                  "open-delay": 500,
                                                },
                                              },
                                              [
                                                _c("review-rating-bar", {
                                                  staticClass:
                                                    "d-inline-flex align-items-center h-100",
                                                  attrs: {
                                                    "number-stars":
                                                      _vm.numberStars,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "review-tags d-flex justify-content-between mb-3",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-items-center flex-wrap",
                                  },
                                  [
                                    _vm.isReview && _vm.isVerified
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content:
                                                "The person writing the review has been verified to have purchased the product from the retailer",
                                              "open-delay": 500,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-tag",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center mr-2",
                                                attrs: { size: "mini" },
                                              },
                                              [
                                                _vm.isVerified
                                                  ? _c("check-circle-icon", {
                                                      staticClass:
                                                        "mr-1 rb-icon-sm",
                                                    })
                                                  : _vm._e(),
                                                _c("span", [
                                                  _vm._v(" Verified Purchase "),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.data && _vm.data.retailer_source
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: `Source of the ${_vm.reviewQuestionTextLabel()}`,
                                              "open-delay": 500,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-tag",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center mr-2",
                                                class: {
                                                  "text-capitalize":
                                                    !_vm.data.retailer_source.includes(
                                                      "www."
                                                    ),
                                                },
                                                attrs: { size: "mini" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.data.retailer_source
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isBazaarvoice
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: `This ${_vm.reviewQuestionTextLabel()} is part of your Bazaarvoice integration.`,
                                              "open-delay": 500,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-tag",
                                              {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  type: "success",
                                                  size: "mini",
                                                },
                                              },
                                              [_vm._v(" Bazaarvoice ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.positiveVotes
                                      ? _c(
                                          "el-tag",
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              type: "info",
                                              size: "mini",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.positiveVotes) +
                                                " positive votes "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.responseTrackingInfo &&
                                    _vm.responseTrackingInfo.helpful_count > 0
                                      ? _c(
                                          "el-tag",
                                          {
                                            attrs: {
                                              type: "info",
                                              size: "mini",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.responseTrackingInfo
                                                    .helpful_count
                                                ) +
                                                " found helpful "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm.userPlan &&
                                _vm.userPlan.plan.enterprise &&
                                _vm.translatedText
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: `Translate ${_vm.reviewQuestionTextLabel()} text to English`,
                                              "open-delay": 500,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                                attrs: {
                                                  type: "primary",
                                                  size: "mini",
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.showTranslateText()
                                                  },
                                                },
                                              },
                                              [
                                                _c("translate-icon", {
                                                  staticClass:
                                                    "mr-1 rb-icon-xs",
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.isTranslate
                                                        ? "Original Text"
                                                        : "Translate"
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _c("div", [
                              !_vm.isReview && (!_vm.data || !_vm.data.title)
                                ? _c("h6", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.reviewQuestionTextLabel(true)
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              (_vm.isTranslate && _vm.translatedText) ||
                              (!_vm.isTranslate && _vm.reviewOrQuestion)
                                ? _c(
                                    "div",
                                    { staticClass: "response-review-text" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "mb-0 review-text" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.isTranslate
                                                  ? _vm.truncateText(
                                                      _vm.translatedText
                                                    )
                                                  : _vm.truncateText(
                                                      _vm.reviewOrQuestion
                                                    )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _vm.showCollapseTextButton
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "text-info",
                                              attrs: {
                                                type: "text",
                                                size: "mini",
                                              },
                                              on: {
                                                click: () =>
                                                  (_vm.isCollapseText =
                                                    !_vm.isCollapseText),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.isCollapseText
                                                      ? "Read more"
                                                      : "Collapse text"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "response-timeline mb-3 rb-response-management-response-timeline",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "timeline-header d-flex align-items-center justify-content-between",
                            },
                            [
                              _c(
                                "h6",
                                [
                                  _vm._v(" Responses "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: `Responses can be from the author of the ${_vm.reviewQuestionTextLabel()} or from users who respond through Reviewbox.`,
                                        "open-delay": 500,
                                      },
                                    },
                                    [
                                      _c("info-icon", {
                                        staticClass:
                                          "text-muted cursor-pointer rb-icon-sm",
                                        attrs: { title: "" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.showTracking
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-between",
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            content: `Enable tracking to collect any additional response(s) from the user of the ${_vm.reviewQuestionTextLabel()}.`,
                                            "open-delay": 500,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.userPlan &&
                                                  !_vm.userPlan.plan.enterprise,
                                              },
                                              on: {
                                                change:
                                                  _vm.onUpdateResponseTracking,
                                              },
                                              model: {
                                                value:
                                                  _vm.responseTrackingStatus,
                                                callback: function ($$v) {
                                                  _vm.responseTrackingStatus =
                                                    $$v
                                                },
                                                expression:
                                                  "responseTrackingStatus",
                                              },
                                            },
                                            [_vm._v(" Enable tracking ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm.userPlan && !_vm.userPlan.plan.enterprise
                            ? _c(
                                "el-alert",
                                { attrs: { type: "info", closable: false } },
                                [
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "alert-heading d-flex align-items-center",
                                    },
                                    [
                                      _c("star-icon", {
                                        staticClass: "mr-1",
                                        attrs: { title: "" },
                                      }),
                                      _vm._v(" Upgrade Your Account "),
                                    ],
                                    1
                                  ),
                                  _vm._v(
                                    " Response tracking is unavailable for your account. Please contact Sales to "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "alert-link text-underline",
                                      attrs: {
                                        href: "https://www.getreviewbox.com/contact-reviewbox",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(" upgrade your account "),
                                      _c(
                                        "sup",
                                        [
                                          _c("external-link-icon", {
                                            staticClass: "rb-icon-xs",
                                            attrs: { title: "" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" . "),
                                ]
                              )
                            : _vm._e(),
                          _vm.userPlan && _vm.userPlan.plan.enterprise
                            ? [
                                _vm.responseTrackingDate
                                  ? _c("el-alert", {
                                      attrs: {
                                        type: "info",
                                        closable: false,
                                        title: `This ${_vm.reviewQuestionTextLabel()} will be tracked until ${
                                          _vm.responseTrackingDate
                                        }.`,
                                        "show-icon": "",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.responseData && _vm.data && _vm.data.source
                                  ? _c(
                                      "div",
                                      [
                                        _c("updates-timeline", {
                                          attrs: {
                                            updates: _vm.responseUpdates,
                                            source: _vm.data.source,
                                            "input-id": _vm.data.id,
                                            "is-review": _vm.isReview,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-4 response-side-panel py-3 bg-gray-light rb-response-management-input-values",
                      },
                      [
                        _vm.product
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "product-information d-flex flex-wrap align-items-center pb-3 border-bottom mb-2",
                              },
                              [
                                _vm.product.imgurl
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "product-image mb-2 text-center",
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "img-fluid",
                                          staticStyle: { "max-height": "60px" },
                                          attrs: {
                                            src: _vm.product.imgurl,
                                            alt: "Product Image",
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.product.imgurl
                                  ? [
                                      _vm.responseData.name &&
                                      _vm.reviewData.product_url
                                        ? _c(
                                            "a",
                                            {
                                              staticClass:
                                                "product-title small",
                                              attrs: {
                                                href: _vm.reviewData
                                                  .product_url,
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.responseData.name
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm.responseData.name &&
                                          _vm.product.url
                                        ? _c(
                                            "a",
                                            {
                                              staticClass:
                                                "product-title small",
                                              attrs: {
                                                href: _vm.product.url,
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.responseData.name
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  : [
                                      _vm.responseData.name &&
                                      _vm.reviewData.product_url
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: "Go to product",
                                                "open-delay": 500,
                                              },
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: _vm.reviewData
                                                      .product_url,
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.responseData.name
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("external-link-icon", {
                                                    staticClass: "rb-icon-xs",
                                                    attrs: { title: "" },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm.responseData.name &&
                                          _vm.product.url
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: "Go to product",
                                                "open-delay": 500,
                                              },
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "el-button el-button--default el-button--mini is-plain rb-product-button",
                                                  attrs: {
                                                    href: _vm.product.url,
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.responseData.name
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("external-link-icon", {
                                                    staticClass: "rb-icon-xs",
                                                    attrs: { title: "" },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.isBazaarvoice && !_vm.isBazaarvoiceConnections
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-column mb-3 pb-3 border-bottom",
                              },
                              [
                                _c("h6", [_vm._v(" Bazaarvoice ")]),
                                _vm.bazaarvoiceModerationStatus
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              "open-delay": 500,
                                              placement: "left",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "max-width": "200px",
                                                },
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "compact-tooltips text-white",
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        "Moderation status"
                                                      ),
                                                    ]),
                                                    _vm._v(
                                                      " is a Bazaarvoice attribute to identify whether a review or question does not violate any rules, such as containing profanity or pricing information. "
                                                    ),
                                                  ]
                                                ),
                                                _c("p", [
                                                  _vm._v(
                                                    " You can learn more about moderation status "
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "https://knowledge.bazaarvoice.com/wp-content/brandedge-pro-wb/en_US/basics/moderation.html",
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [_vm._v("here")]
                                                  ),
                                                  _vm._v(". "),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "el-alert",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center p-2 mb-2",
                                                attrs: {
                                                  id: "bazaarvoice-moderation-status",
                                                  closable: false,
                                                  type: _vm.moderationStatusType,
                                                  "show-icon": "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-capitalize",
                                                    attrs: { slot: "title" },
                                                    slot: "title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.bazaarvoiceModerationStatus
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-column mb-2" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "bazaarvoice-approval-status",
                                          },
                                        },
                                        [_vm._v(" Moderation ")]
                                      ),
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            "open-delay": 500,
                                            content: `Approve the ${_vm.reviewQuestionTextLabel()}`,
                                            placement: "left",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              staticClass: "mb-2",
                                              attrs: { label: "approve" },
                                              model: {
                                                value:
                                                  _vm.bazaarvoiceApprovalStatus,
                                                callback: function ($$v) {
                                                  _vm.bazaarvoiceApprovalStatus =
                                                    $$v
                                                },
                                                expression:
                                                  "bazaarvoiceApprovalStatus",
                                              },
                                            },
                                            [_c("span", [_vm._v("Approve")])]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            "open-delay": 500,
                                            content: `Reject the ${_vm.reviewQuestionTextLabel()}`,
                                            placement: "left",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            {
                                              attrs: { label: "reject" },
                                              model: {
                                                value:
                                                  _vm.bazaarvoiceApprovalStatus,
                                                callback: function ($$v) {
                                                  _vm.bazaarvoiceApprovalStatus =
                                                    $$v
                                                },
                                                expression:
                                                  "bazaarvoiceApprovalStatus",
                                              },
                                            },
                                            [_c("span", [_vm._v("Reject")])]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.bazaarvoiceApprovalStatus == "reject"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-column mb-2",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "bazaarvoice-moderation-code",
                                              },
                                            },
                                            [
                                              _vm._v(" Moderator codes "),
                                              _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    "open-delay": 500,
                                                    placement: "top",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "max-width": "200px",
                                                      },
                                                      attrs: {
                                                        slot: "content",
                                                      },
                                                      slot: "content",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "compact-tooltips text-white",
                                                        },
                                                        [
                                                          _c("strong", [
                                                            _vm._v(
                                                              "Moderator codes"
                                                            ),
                                                          ]),
                                                          _vm._v(
                                                            " are used within Bazaarvoice to select reasons for rejecting a review or question. "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("p", [
                                                        _vm._v(
                                                          " You can learn more about moderator codes "
                                                        ),
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "https://knowledge.bazaarvoice.com/wp-content/conversations/en_US/Learn/moderation.html#rejection-codes",
                                                              target: "_blank",
                                                            },
                                                          },
                                                          [_vm._v("here")]
                                                        ),
                                                        _vm._v(". "),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c("info-icon", {
                                                    staticClass:
                                                      "cursor-pointer text-muted rb-icon-sm",
                                                    attrs: { title: "" },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "select-primary",
                                              attrs: {
                                                id: "bazaarvoice-moderation-code",
                                                name: "bazaarvoice-moderation-code",
                                                placeholder:
                                                  "Select moderation code",
                                                filterable: "",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.bazaarvoiceModerationCode,
                                                callback: function ($$v) {
                                                  _vm.bazaarvoiceModerationCode =
                                                    $$v
                                                },
                                                expression:
                                                  "bazaarvoiceModerationCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.bazaarvoiceModerationCodes,
                                              function (option, optionIndex) {
                                                return _c(
                                                  "el-option",
                                                  {
                                                    key: `${option.value}--${optionIndex}`,
                                                    attrs: {
                                                      value: option.value,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(option.value) +
                                                        ": " +
                                                        _vm._s(option.label) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "flex-grow-1",
                                          attrs: {
                                            "open-delay": 500,
                                            placement: "left",
                                            disabled:
                                              _vm.isModerationStatusValid,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "content",
                                                fn: function () {
                                                  return [
                                                    !_vm.bazaarvoiceApprovalStatus
                                                      ? _c("div", [
                                                          _vm._v(
                                                            " Please select to approve or reject the " +
                                                              _vm._s(
                                                                _vm.reviewQuestionTextLabel()
                                                              ) +
                                                              " "
                                                          ),
                                                        ])
                                                      : _c("div", [
                                                          _vm._v(
                                                            " Please select one or more "
                                                          ),
                                                          _c("strong", [
                                                            _vm._v(
                                                              "Moderator codes"
                                                            ),
                                                          ]),
                                                          _vm._v(
                                                            " if you are rejecting a " +
                                                              _vm._s(
                                                                _vm.reviewQuestionTextLabel()
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            2749858451
                                          ),
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass: "w-100",
                                                  attrs: {
                                                    type: "primary",
                                                    size: "mini",
                                                    disabled:
                                                      _vm.isBvUpdateDisabled ||
                                                      _vm.isLoading ||
                                                      !_vm.isModerationStatusValid,
                                                    loading:
                                                      _vm.isModerationStatusLoading,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.onUpdateBazaarvoiceModerationStatus,
                                                  },
                                                },
                                                [
                                                  _vm.bazaarvoiceApprovalStatus
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-capitalize",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.bazaarvoiceApprovalStatus
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _c("span", [
                                                        _vm._v(" Update "),
                                                      ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.reviewQuestionTextLabel()
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-column mb-3" },
                          [
                            _c("label", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.headerFieldNames["status"] || "Status"
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "el-select",
                              {
                                attrs: { name: "status-input", filterable: "" },
                                on: {
                                  change: () =>
                                    _vm.onUpdateValues({
                                      status: _vm.selectedStatus,
                                    }),
                                },
                                model: {
                                  value: _vm.selectedStatus,
                                  callback: function ($$v) {
                                    _vm.selectedStatus = $$v
                                  },
                                  expression: "selectedStatus",
                                },
                              },
                              _vm._l(
                                _vm.statusOptions,
                                function (option, optionIndex) {
                                  return _c("el-option", {
                                    key: `${option}--${optionIndex}`,
                                    attrs: { label: option, value: option },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.isReview
                          ? _c(
                              "div",
                              { staticClass: "d-flex flex-column mb-3" },
                              [
                                _c(
                                  "label",
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.headerFieldNames["sentiment"] ||
                                            "Sentiment"
                                        ) +
                                        " "
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content:
                                            "The overall feeling of the review",
                                          "open-delay": 500,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c("info-icon", {
                                          staticClass:
                                            "rb-icon-sm cursor-pointer text-muted",
                                          attrs: { title: "" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      name: "sentiment-input",
                                      filterable: "",
                                    },
                                    on: {
                                      change: () =>
                                        _vm.onUpdateValues({
                                          sentiment: _vm.selectedSentiment,
                                        }),
                                    },
                                    model: {
                                      value: _vm.selectedSentiment,
                                      callback: function ($$v) {
                                        _vm.selectedSentiment = $$v
                                      },
                                      expression: "selectedSentiment",
                                    },
                                  },
                                  _vm._l(
                                    _vm.sentimentOptions,
                                    function (option, optionIndex) {
                                      return _c("el-option", {
                                        key: `${option}--${optionIndex}`,
                                        attrs: { label: option, value: option },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-column mb-3" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "response-topic-input" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.headerFieldNames["topic"] || "Topic"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "el-select",
                              {
                                staticClass: "select-primary",
                                attrs: {
                                  id: "response-topic-input",
                                  name: "topic-input",
                                  filterable: "",
                                  multiple: "",
                                  clearable: "",
                                  "collapse-tags": "",
                                  "popper-append-to-body": false,
                                },
                                on: {
                                  change: () =>
                                    _vm.onUpdateValues({
                                      topic: _vm.selectedTopic,
                                    }),
                                },
                                model: {
                                  value: _vm.selectedTopic,
                                  callback: function ($$v) {
                                    _vm.selectedTopic = $$v
                                  },
                                  expression: "selectedTopic",
                                },
                              },
                              [
                                _vm._l(
                                  _vm.topicOptions,
                                  function (option, optionIndex) {
                                    return _c("el-option", {
                                      key: `${option}--${optionIndex}`,
                                      attrs: { label: option, value: option },
                                    })
                                  }
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column align-items-center p-2",
                                    attrs: { slot: "empty" },
                                    slot: "empty",
                                  },
                                  [
                                    _c("span", [_vm._v(" No topics found. ")]),
                                    !_vm.topicOptions ||
                                    !_vm.topicOptions.length
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onAddDropdownValues(
                                                  "topic"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" Add more topics ")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-column mb-3" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "response-custom-col-input" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.headerFieldNames["customCol"] ||
                                        "Custom"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "el-select",
                              {
                                staticClass: "select-primary",
                                attrs: {
                                  id: "response-custom-col-input",
                                  name: "custom-input",
                                  filterable: "",
                                  multiple: "",
                                  clearable: "",
                                  "collapse-tags": "",
                                },
                                on: {
                                  change: () =>
                                    _vm.onUpdateValues({
                                      customCol: _vm.selectedCustomCol,
                                    }),
                                },
                                model: {
                                  value: _vm.selectedCustomCol,
                                  callback: function ($$v) {
                                    _vm.selectedCustomCol = $$v
                                  },
                                  expression: "selectedCustomCol",
                                },
                              },
                              [
                                _vm._l(
                                  _vm.customColOptions,
                                  function (option, optionIndex) {
                                    return _c("el-option", {
                                      key: `${option}--${optionIndex}`,
                                      attrs: { label: option, value: option },
                                    })
                                  }
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column align-items-center p-2",
                                    attrs: { slot: "empty" },
                                    slot: "empty",
                                  },
                                  [
                                    _c("span", [_vm._v(" No values found. ")]),
                                    !_vm.customColOptions ||
                                    !_vm.customColOptions.length
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onAddDropdownValues(
                                                  "customCol"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" Add more values ")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-column mb-3" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "response-reason-code-input" } },
                              [
                                _vm._v(" Reason codes "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      "open-delay": 500,
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _c("p", { staticClass: "text-white" }, [
                                          _vm._v(
                                            " Reason codes are automatically generated based on your defined review topics, and they provide a general sense of what the review is about. "
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            " Learn more about setting up user-defined topics in our "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "https://helpcenter.getreviewbox.com/voice-of-customer-report",
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(" Help Center "),
                                              _c("external-link-icon", {
                                                staticClass: "rb-icon-xs",
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(". "),
                                        ]),
                                      ]
                                    ),
                                    _c("info-icon", {
                                      staticClass:
                                        "rb-icon-sm text-muted cursor-pointer",
                                      attrs: { title: "" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-select",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.isReasonsLoading,
                                    expression: "isReasonsLoading",
                                  },
                                ],
                                staticClass:
                                  "select-primary is-disabled reason-code-spinner",
                                attrs: {
                                  id: "response-reason-code-input",
                                  placeholder: "No reason codes found",
                                  name: "reason-input",
                                  multiple: "",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.selectedReasons,
                                  callback: function ($$v) {
                                    _vm.selectedReasons = $$v
                                  },
                                  expression: "selectedReasons",
                                },
                              },
                              _vm._l(
                                _vm.reasons,
                                function (option, optionIndex) {
                                  return _c("el-option", {
                                    key: `${option}--${optionIndex}`,
                                    attrs: { label: option, value: option },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "form-group rb-response-management-notes pt-3 border-top",
                          },
                          [
                            _c(
                              "label",
                              { attrs: { for: "notes-input" } },
                              [
                                _c("span", { staticClass: "h6" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.headerFieldNames["notes"] || "Notes"
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: `Add additional information or context for this ${_vm.reviewQuestionTextLabel()}.`,
                                      "open-delay": 500,
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c("info-icon", {
                                      staticClass:
                                        "text-muted cursor-pointer rb-icon-sm",
                                      attrs: { title: "" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("el-input", {
                              attrs: {
                                id: "notes-input",
                                type: "textarea",
                                autosize: { minRows: 3, maxRows: 6 },
                                placeholder: "Add your notes",
                                debounce: 500,
                              },
                              on: {
                                blur: _vm.onGenericBlur,
                                focus: _vm.onGenericFocus,
                                change: () =>
                                  _vm.onUpdateValues({
                                    comment: _vm.commentText,
                                  }),
                              },
                              model: {
                                value: _vm.commentText,
                                callback: function ($$v) {
                                  _vm.commentText = $$v
                                },
                                expression: "commentText",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          !_vm.isLoading && !_vm.isLoadingFailed
            ? _c(
                "div",
                {
                  staticClass:
                    "response-drawer-footer bg-white rb-response-management-responding",
                  class: { "has-focus": _vm.isResponseInputFocused },
                },
                [
                  _vm.isAmazon && _vm.isReview
                    ? _c(
                        "div",
                        { staticClass: "response-amazon-alert" },
                        [
                          _c(
                            "el-alert",
                            {
                              staticClass: "mb-2",
                              attrs: {
                                type: "info",
                                closable: false,
                                "show-icon": "",
                              },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "title" }, slot: "title" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "el-alert__title is-bold" },
                                    [
                                      _vm._v(
                                        " Amazon review response is disabled. "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" Read about "),
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "font-weight-bold text-info text-underline",
                                      attrs: {
                                        href: "https://www.getreviewbox.com/blog/amazon-removes-its-review-commenting-feature",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Amazon removing their commenting feature here. "
                                      ),
                                      _c("external-link-icon", {
                                        staticClass: "rb-icon-xs",
                                        attrs: { title: "" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  (!_vm.isAmazon && _vm.isReview) || !_vm.isReview
                    ? [
                        _c(
                          "div",
                          { staticClass: "response-input-toolbar row mb-2" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-6 d-flex align-items-end pr-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex flex-column flex-fill",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "rb-text-help",
                                        attrs: {
                                          for: "response-templates-select",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [_vm._v(" Response templates ")]
                                        ),
                                        _c(
                                          "el-tooltip",
                                          { attrs: { "open-delay": 500 } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "text-white" },
                                                  [
                                                    _vm._v(
                                                      " Response templates are pre-populated answers that your team can use to quickly respond to reviews and questions. "
                                                    ),
                                                  ]
                                                ),
                                                _c("p", [
                                                  _vm._v(
                                                    " Learn more about how to set up and use response templates in our "
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "https://helpcenter.getreviewbox.com/how-to-set-up-and-use-response-templates",
                                                        target: "_blank",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" Help Center "),
                                                      _c("external-link-icon", {
                                                        staticClass:
                                                          "rb-icon-xs",
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(". "),
                                                ]),
                                              ]
                                            ),
                                            _c("info-icon", {
                                              staticClass:
                                                "rb-icon-sm cursor-pointer text-muted",
                                              attrs: { title: "" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          id: "response-templates-select",
                                          placeholder:
                                            "Apply one or more response templates",
                                          size: "mini",
                                          disabled:
                                            _vm.isLoading ||
                                            (_vm.isAmazon && _vm.isReview) ||
                                            !_vm.hasIntegrations ||
                                            _vm.isDraftLoading,
                                          clearable: "",
                                          filterable: "",
                                          multiple: "",
                                        },
                                        on: {
                                          change: _vm.onSelectResponseTemplate,
                                        },
                                        model: {
                                          value: _vm.selectedResponseTemplates,
                                          callback: function ($$v) {
                                            _vm.selectedResponseTemplates = $$v
                                          },
                                          expression:
                                            "selectedResponseTemplates",
                                        },
                                      },
                                      _vm._l(
                                        _vm.filteredResponseTemplates,
                                        function (option) {
                                          return _c("el-option", {
                                            key: option.name,
                                            attrs: {
                                              value: option.response,
                                              label: option.name,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.hasCustomFilters
                                  ? _c(
                                      "div",
                                      { staticClass: "ml-2" },
                                      [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              width: "400",
                                              trigger: "click",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "h6",
                                              { staticClass: "text-muted" },
                                              [
                                                _vm._v(
                                                  " Response template filters "
                                                ),
                                              ]
                                            ),
                                            _vm._l(
                                              _vm.customFilters,
                                              function (filter, name, idx) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: `custom-filters--${idx}`,
                                                    staticClass:
                                                      "d-flex flex-column mb-2",
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "rb-text-help text-capitalize",
                                                        attrs: {
                                                          for: `custom-filter--${name}`,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(name) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticClass:
                                                          "select-primary",
                                                        attrs: {
                                                          id: `custom-filter--${name}`,
                                                          size: "mini",
                                                          multiple: "",
                                                          filterable: "",
                                                          placeholder:
                                                            "Filter by " + name,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .customSelectedFilters[
                                                              name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.customSelectedFilters,
                                                              name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "customSelectedFilters[name]",
                                                        },
                                                      },
                                                      _vm._l(
                                                        filter,
                                                        function (f, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key:
                                                                filter + index,
                                                              staticClass:
                                                                "select-primary",
                                                              attrs: {
                                                                value: f,
                                                                label: f,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-content-end",
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "light",
                                                      size: "mini",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.customSelectedFilters =
                                                          {}
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Reset filters ")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  slot: "reference",
                                                  content:
                                                    "Filter your response templates",
                                                  "open-delay": 500,
                                                  disabled:
                                                    _vm.isLoading ||
                                                    (_vm.isAmazon &&
                                                      _vm.isReview) ||
                                                    !_vm.hasIntegrations ||
                                                    _vm.isDraftLoading,
                                                },
                                                slot: "reference",
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "px-2",
                                                    class: {
                                                      "el-button--primary":
                                                        _vm.hasCustomSelectedFilters,
                                                    },
                                                    attrs: {
                                                      size: "mini",
                                                      plain:
                                                        _vm.hasCustomSelectedFilters,
                                                      disabled:
                                                        _vm.isLoading ||
                                                        (_vm.isAmazon &&
                                                          _vm.isReview) ||
                                                        !_vm.hasIntegrations ||
                                                        _vm.isDraftLoading,
                                                    },
                                                  },
                                                  [
                                                    _c("filter-icon", {
                                                      staticClass: "rb-icon-xs",
                                                      attrs: { title: "" },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-column" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "rb-text-help",
                                      attrs: { for: "response-account-select" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [_vm._v(" Response account ")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        id: "response-account-select",
                                        placeholder: "Select response account",
                                        "value-key": "public",
                                        disabled:
                                          _vm.isLoading ||
                                          (_vm.isAmazon && _vm.isReview) ||
                                          !_vm.hasIntegrations ||
                                          _vm.isDraftLoading,
                                        size: "mini",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.selectedResponseIntegration,
                                        callback: function ($$v) {
                                          _vm.selectedResponseIntegration = $$v
                                        },
                                        expression:
                                          "selectedResponseIntegration",
                                      },
                                    },
                                    _vm._l(
                                      _vm.responseIntegrationOptions,
                                      function (responseIntegration, index) {
                                        return _c("el-option", {
                                          key: `${responseIntegration.public}_${index}`,
                                          attrs: {
                                            value: {
                                              public:
                                                responseIntegration.public,
                                              type: responseIntegration.type,
                                            },
                                            label: responseIntegration.public,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "response-message-container mb-3" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  "open-delay": 500,
                                  disabled: _vm.hasIntegrations,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _vm.hasEnterprise
                                      ? [
                                          _c("p", { staticClass: "mb-2" }, [
                                            _vm._v(
                                              " Your account does not have access to responding to "
                                            ),
                                            _vm.data && _vm.data.source
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-capitalize",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.data.source)
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.reviewQuestionTextLabel(
                                                    false,
                                                    true
                                                  )
                                                ) +
                                                ". "
                                            ),
                                          ]),
                                        ]
                                      : [
                                          _c("p", { staticClass: "mb-2" }, [
                                            _vm._v(
                                              " Your account does not have access to responding to " +
                                                _vm._s(
                                                  _vm.reviewQuestionTextLabel(
                                                    false,
                                                    true
                                                  )
                                                ) +
                                                ". "
                                            ),
                                          ]),
                                          _c("p", { staticClass: "mb-0" }, [
                                            _vm._v(" Please contact Sales to "),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "text-underline",
                                                attrs: {
                                                  href: "https://www.getreviewbox.com/contact-reviewbox",
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " upgrade your account "
                                                ),
                                                _c("external-link-icon", {
                                                  staticClass: "rb-icon-xs",
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(
                                              " and gain access to Response Integrations. "
                                            ),
                                          ]),
                                        ],
                                  ],
                                  2
                                ),
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.createValidationObject({
                                        type: "responseMessage",
                                        required: true,
                                      }),
                                      expression:
                                        'createValidationObject({ type: "responseMessage", required: true })',
                                    },
                                  ],
                                  ref: "response-drawer-input",
                                  staticClass: "mb-2",
                                  class: {
                                    "is-invalid":
                                      _vm.errors.first("responseMessage"),
                                  },
                                  attrs: {
                                    id: "response-drawer-input",
                                    name: "responseMessage",
                                    placeholder: "Add a response...",
                                    size: "medium",
                                    type: "textarea",
                                    autosize: "",
                                    error: _vm.errors.first("responseMessage"),
                                    disabled:
                                      _vm.isLoading ||
                                      (_vm.isAmazon && _vm.isReview) ||
                                      !_vm.hasIntegrations ||
                                      _vm.isDraftLoading,
                                    autocomplete: "off",
                                  },
                                  on: {
                                    blur: _vm.onGenericBlur,
                                    focus: _vm.onResponseInputFocus,
                                    input: _vm.onResponseInput,
                                  },
                                  model: {
                                    value: _vm.form.responseMessage,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "responseMessage", $$v)
                                    },
                                    expression: "form.responseMessage",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.formErrorMessage
                              ? _c(
                                  "el-alert",
                                  {
                                    attrs: {
                                      type: "error",
                                      title: "Unable to submit response",
                                      closable: false,
                                      "show-icon": "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.formErrorMessage) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.errors.first("responseMessage")
                              ? _c("small", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.errors.first("responseMessage")
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "response-actions d-flex" }, [
                          _c(
                            "div",
                            { staticClass: "right-actions ml-auto" },
                            [
                              _vm.hasResponseChanged && !_vm.isConfirmSubmit
                                ? _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content:
                                          "Drafts can be revisited at anytime",
                                        "open-delay": 500,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "border-0",
                                          attrs: {
                                            size: "small",
                                            loading: _vm.isLoading,
                                            disabled:
                                              _vm.errors.any() ||
                                              (_vm.isAmazon && _vm.isReview) ||
                                              !_vm.hasIntegrations ||
                                              _vm.isDraftLoading,
                                          },
                                          on: { click: _vm.onSaveDraft },
                                        },
                                        [_vm._v(" Save draft ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isConfirmSubmit
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex flex-column" },
                                    [
                                      _c(
                                        "small",
                                        {
                                          staticClass:
                                            "text-muted font-weight-bold mb-2",
                                        },
                                        [
                                          _vm._v(
                                            " Are you sure you want to leave a reply? "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center justify-content-end",
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                loading: _vm.isLoading,
                                                plain: "",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.isConfirmSubmit = false
                                                },
                                              },
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                slot: "reference",
                                                type: "primary",
                                                size: "small",
                                                loading: _vm.isLoading,
                                                disabled:
                                                  _vm.errors.any() ||
                                                  (_vm.isAmazon &&
                                                    _vm.isReview) ||
                                                  !_vm.hasIntegrations ||
                                                  _vm.isDraftLoading,
                                              },
                                              on: {
                                                click:
                                                  _vm.confirmSubmitResponse,
                                              },
                                              slot: "reference",
                                            },
                                            [_vm._v(" Yes, leave reply ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "primary",
                                          size: "small",
                                          loading: _vm.isLoading,
                                          disabled:
                                            _vm.errors.any() ||
                                            (_vm.isAmazon && _vm.isReview) ||
                                            !_vm.hasIntegrations ||
                                            _vm.isDraftLoading,
                                        },
                                        on: { click: _vm.onSubmitResponse },
                                        slot: "reference",
                                      },
                                      [_vm._v(" Leave reply ")]
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }